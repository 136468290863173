import { Modal } from "antd"
import React from "react"
import SuccessMessageIcon from "../../../../assets/icons/SuccessMessageIcon"
import { useTranslations } from "next-intl"

import styles from "./callBackMessage.module.scss"

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const CallBackMessage: React.FC<Props> = ({ open, setOpen }) => {
  const t = useTranslations("common.CallBackModal")
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      width={400}
      centered
      className={styles.messageModal}
      title={
        <div className={styles.messageTitle}>
          <SuccessMessageIcon />
          <div className={styles.messageTitle_right}>
            <h4>{t("message_title")}</h4>
            <span>{t("message_descr")}</span>
          </div>
        </div>
      }
    />
  )
}

export default CallBackMessage
