import React from "react"

const WalkIcon = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43649 4.37499L3.38073 2.96276C3.59898 2.80304 3.86445 2.72103 4.13476 2.72982C4.46447 2.73804 4.78342 2.84878 5.04728 3.04665C5.31114 3.24452 5.50679 3.51968 5.60705 3.83388C5.71988 4.18754 5.82301 4.42655 5.91643 4.5503C6.19863 4.92701 6.56476 5.23275 6.98576 5.44325C7.40676 5.65374 7.87103 5.76321 8.34172 5.76295V6.9762C7.71535 6.97679 7.09665 6.83853 6.53012 6.57137C5.96359 6.30421 5.46333 5.91478 5.06533 5.43112L4.6419 7.83094L5.89216 8.8798L7.24069 12.5851L6.10023 13L4.86332 9.60047L2.80686 7.87461C2.63799 7.73841 2.50873 7.55941 2.43255 7.35626C2.35637 7.15312 2.33606 6.93326 2.37373 6.7196L2.6825 4.96948L2.27182 5.26794L0.981521 7.04414L0 6.33075L1.42618 4.36771L1.43649 4.37499ZM5.00527 2.4265C4.6835 2.4265 4.3749 2.29868 4.14737 2.07115C3.91985 1.84362 3.79202 1.53503 3.79202 1.21325C3.79202 0.891478 3.91985 0.582882 4.14737 0.355353C4.3749 0.127824 4.6835 0 5.00527 0C5.32705 0 5.63564 0.127824 5.86317 0.355353C6.0907 0.582882 6.21852 0.891478 6.21852 1.21325C6.21852 1.53503 6.0907 1.84362 5.86317 2.07115C5.63564 2.29868 5.32705 2.4265 5.00527 2.4265ZM3.20359 10.4224L1.2539 12.7458L0.324545 11.9663L2.12926 9.81521L2.5818 8.49277L3.66827 9.40271L3.20359 10.4224Z"
        fill="#DC7A16"
      />
    </svg>
  )
}

export default WalkIcon
