import { Button, Dropdown } from "antd"
import React, { useEffect, useState } from "react"

import style from "./headerDropdown.module.scss"
import { layoutLang } from "../../../../../utils/language/layout"
import { RU, USD, UZ, UZS } from "../../../../../utils/constants/locales"
import ArrowBottom from "../../../../../assets/icons/ArrowBottom"
import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { localeReducerActions } from "../../../../../store/reducers/localeReducer"
import UzFlagIcon from "assets/icons/UzFlagIcon"
import RuFlagIcon from "assets/icons/RuFlagIcon"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import CurrencyIcon from "assets/icons/CurrencyIcon"

interface IProps {
  type: "lang" | "currency"
}

const HeaderDropDown: React.FC<IProps> = ({ type }) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { lang, currency } = useAppSelector((state) => state.localeReducer)
  const { changeLanguage, changeCurrency, changeUsdValue } =
    localeReducerActions
  const [visible, setVisible] = useState(false)

  // useEffect(() => {
  //   fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`)
  //     .then((res) => res.json())
  //     .then((res: any[]) => {
  //       dispatch(changeUsdValue(res?.find((item) => item?.Ccy === "USD")?.Rate))
  //     })

  //   dispatch(changeLanguage(router?.locale === RU ? RU : UZ))
  // }, [])

  const handleChangeLang = (language: string) => {
    dispatch(changeLanguage(language))
    setVisible(false)
  }

  const handleChangeCurrency = (cur: string) => {
    dispatch(changeCurrency(cur))
  }

  const handleRoute = () =>
    router.push(router.asPath, router.asPath, { locale: lang })

  const handleSave = () => {
    handleRoute()
    setTimeout(() => {
      setVisible(false)
    }, 800)
  }

  return (
    <Dropdown
      placement="bottomRight"
      open={visible}
      overlay={
        <div
          className="bg-white rounded-lg w-[240px] select-none"
          style={{
            boxShadow:
              "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
          }}
          onMouseLeave={handleSave}
        >
          {type === "lang" ? (
            <div>
              <div
                className="flex items-center gap-3 px-4 py-2.5 border-b border-solid border-[#F9FAFB] cursor-pointer relative"
                onClick={() => handleChangeLang("uz")}
              >
                <UzFlagIcon />
                <span>O‘zbekcha</span>
                {lang === "uz" && (
                  <div className="absolute right-4">
                    <RegCheckIcon />
                  </div>
                )}
              </div>
              <div
                className="flex items-center gap-3 px-4 py-2.5 cursor-pointer relative"
                onClick={() => handleChangeLang("ru")}
              >
                <RuFlagIcon />
                <span>Русский</span>
                {lang === "ru" && (
                  <div className="absolute right-4">
                    <RegCheckIcon />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                className="flex items-center gap-3 px-4 py-2.5 border-b border-solid border-[#EAECF0] cursor-pointer relative text-sm text-[#344054] font-medium"
                onClick={() => {
                  handleChangeCurrency("uzs")
                }}
              >
                {layoutLang[String(router.locale)]["ozbek_somi"]}
                {currency === "uzs" && (
                  <div className="absolute right-4">
                    <RegCheckIcon />
                  </div>
                )}
              </div>
              <div
                className="flex items-center gap-3 px-4 py-2.5 cursor-pointer relative text-sm text-[#344054] font-medium"
                onClick={() => {
                  handleChangeCurrency("usd")
                }}
              >
                {layoutLang[String(router.locale)]["aqsh_dollari"]}
                {currency === "usd" && (
                  <div className="absolute right-4">
                    <RegCheckIcon />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      }
      trigger={["click", "hover"]}
      mouseEnterDelay={1000}
      mouseLeaveDelay={0.3}
      overlayClassName={style.overlayClassDrop}
      onOpenChange={() => setVisible(!visible)}
    >
      <div>
        {type === "lang" ? (
          <div
            className={`flex items-center gap-2.5 border border-solid border-[#EAECF0] rounded-2xl h-[47px] px-4 cursor-pointer`}
            onClick={() => setVisible(!visible)}
          >
            {lang === "uz" ? (
              <>
                <UzFlagIcon />
                <span className="text-[#475467] text-base font-semibold">
                  O‘z
                </span>
              </>
            ) : (
              <>
                <RuFlagIcon />
                <span className="text-[#475467] text-base font-semibold">
                  Ру
                </span>
              </>
            )}
          </div>
        ) : (
          <div
            className={`flex items-center gap-2.5 border border-solid border-[#EAECF0] rounded-2xl h-[47px] px-4 cursor-pointer`}
          >
            <CurrencyIcon />
            <span className="text-[#475467] text-base font-semibold">
              {currency?.toUpperCase()}
            </span>
          </div>
        )}
      </div>
    </Dropdown>
  )
}

export default HeaderDropDown
