import React from "react"

const NotifIcon = () => {
  return (
    <svg
      width="188"
      height="196"
      viewBox="0 0 188 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9394 76.8398L27.4395 79.8725L26.9405 76.8398L23.9077 76.3407L26.9405 75.8409L27.4395 72.809L27.9394 75.8409L30.9713 76.3407L27.9394 76.8398Z"
        fill="#05603A"
      />
      <path
        d="M21.1823 65.5043L20.3756 70.6357L19.5689 65.5043L14.6704 64.6593L19.5689 63.8134L20.3756 58.682L21.1823 63.8134L26.0808 64.6593L21.1823 65.5043Z"
        fill="#05603A"
      />
      <path
        d="M184.968 120.309L184.468 123.34L183.969 120.309L180.936 119.809L183.969 119.31L184.468 116.277L184.968 119.31L188 119.809L184.968 120.309Z"
        fill="#05603A"
      />
      <path
        d="M177.125 109.515L176.318 114.647L175.511 109.515L170.612 108.67L175.511 107.825L176.318 102.693L177.125 107.825L182.023 108.67L177.125 109.515Z"
        fill="#05603A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.243 117.24C170.953 117.24 169.907 118.39 169.907 119.809C169.907 121.228 170.953 122.378 172.243 122.378C173.532 122.378 174.578 121.228 174.578 119.809C174.578 118.39 173.532 117.24 172.243 117.24ZM169.526 119.809C169.526 118.159 170.742 116.821 172.243 116.821C173.743 116.821 174.959 118.159 174.959 119.809C174.959 121.46 173.743 122.798 172.243 122.798C170.742 122.798 169.526 121.46 169.526 119.809Z"
        fill="#05603A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4162 63.9535C31.9974 63.9535 30.8473 64.999 30.8473 66.2888C30.8473 67.5785 31.9974 68.6241 33.4162 68.6241C34.8349 68.6241 35.985 67.5785 35.985 66.2888C35.985 64.999 34.8349 63.9535 33.4162 63.9535ZM30.4277 66.2888C30.4277 64.7884 31.7657 63.572 33.4162 63.572C35.0666 63.572 36.4046 64.7884 36.4046 66.2888C36.4046 67.7892 35.0666 69.0055 33.4162 69.0055C31.7657 69.0055 30.4277 67.7892 30.4277 66.2888Z"
        fill="#05603A"
      />
      <path
        d="M77.7356 127.926C75.6988 128.467 73.6612 129.009 71.6244 129.551C71.5327 129.575 71.4441 129.61 71.3595 129.651C67.7662 131.411 63.4054 129.984 61.5641 126.42L58.8396 121.145C58.4814 120.451 58.755 119.599 59.4507 119.242L72.3233 112.63C73.019 112.273 73.8734 112.546 74.2316 113.24L76.7096 118.037C76.8604 118.329 76.9067 118.664 76.8341 118.985C76.6139 119.963 76.3562 122.001 77.4237 124.117C77.7228 124.712 78.0763 125.212 78.4377 125.629C79.123 126.423 78.7496 127.656 77.7356 127.926Z"
        fill="#6CE9A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.9508 112.995C73.649 112.394 72.9291 112.158 72.343 112.467L59.7209 119.131L59.7209 119.131C59.1348 119.44 58.9042 120.178 59.206 120.779C59.206 120.779 59.206 120.779 59.206 120.779L61.8775 126.096C63.6341 129.592 67.7943 130.991 71.2222 129.265L71.2224 129.265C71.3154 129.218 71.414 129.179 71.5173 129.15L71.5178 129.15C72.5149 128.877 73.5122 128.605 74.5095 128.332L74.514 128.331C75.5127 128.058 76.5115 127.785 77.5101 127.512C78.3627 127.279 78.68 126.212 78.1008 125.522C77.7371 125.09 77.3812 124.572 77.0799 123.957C76.0044 121.765 76.265 119.657 76.4857 118.65L76.4857 118.65C76.5465 118.374 76.5081 118.084 76.3806 117.83C76.3806 117.83 76.3806 117.83 76.3806 117.83L73.9508 112.995L73.9508 112.995ZM74.2986 112.811C73.8979 112.014 72.9421 111.7 72.164 112.111L72.1639 112.111L59.5418 118.775C58.7637 119.185 58.4576 120.165 58.8583 120.963L58.8583 120.963L61.5298 126.279L61.7036 126.188L61.5298 126.279C63.3842 129.97 67.7758 131.447 71.3947 129.625C71.4673 129.588 71.5422 129.558 71.6187 129.537C72.6172 129.264 73.616 128.991 74.6147 128.718L74.6192 128.717C75.6165 128.444 76.6138 128.172 77.6109 127.899C78.7468 127.589 79.1617 126.17 78.3971 125.26L78.3968 125.26C78.0519 124.851 77.7147 124.36 77.4294 123.777L77.4293 123.777C76.4114 121.702 76.6562 119.701 76.8673 118.738C76.9489 118.367 76.8965 117.981 76.7283 117.646L76.7283 117.646L74.2986 112.811Z"
        fill="#05603A"
      />
      <path
        d="M86.7762 156.004C86.4733 158.22 85.7369 160.381 84.5801 162.355C84.2884 162.852 83.971 163.337 83.6262 163.808C82.3526 165.55 79.7633 165.646 78.3535 164.01L62.445 145.541L50.2465 131.379C49.0437 129.982 49.2129 127.885 50.6243 126.696L52.5022 125.113L56.9435 121.37L58.8206 119.787C60.232 118.597 62.3516 118.765 63.5536 120.161L75.753 134.323L82.7594 142.457C86.1164 146.355 87.4207 151.3 86.7762 156.004Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0119 119.598C59.0119 119.598 59.0119 119.598 59.0119 119.598L50.9344 126.529C49.6269 127.65 49.4701 129.627 50.5844 130.944L78.2841 163.677C79.5904 165.22 81.989 165.129 83.1685 163.487L83.1686 163.486C83.5044 163.019 83.8135 162.539 84.0976 162.046C85.2245 160.089 85.9418 157.946 86.2369 155.749C86.8648 151.085 85.5942 146.181 82.324 142.316C82.324 142.316 82.324 142.316 82.324 142.316L63.3965 119.951C62.2831 118.634 60.3195 118.476 59.0119 119.598ZM63.6986 119.691C62.4429 118.207 60.2288 118.029 58.7544 119.294L50.677 126.225C50.677 126.225 50.677 126.225 50.677 126.225C49.2025 127.489 49.0258 129.719 50.2822 131.203L77.9819 163.936C79.4543 165.676 82.1591 165.574 83.4901 163.721M63.6986 119.691C63.6986 119.691 63.6987 119.691 63.6986 119.691V119.691Z"
        fill="#05603A"
      />
      <path
        d="M86.777 155.97C86.4746 158.194 85.7395 160.364 84.5845 162.344C84.2933 162.844 83.9764 163.33 83.6322 163.803C82.3606 165.552 79.7755 165.648 78.368 164.006L62.4854 145.468L66.9115 141.716L71.3456 137.959L75.7717 134.208L82.7667 142.372C86.1183 146.284 87.4205 151.248 86.777 155.97Z"
        fill="#05603A"
      />
      <path
        d="M143.444 79.8722C151.547 79.8722 158.115 73.304 158.115 65.2017C158.115 57.0994 151.547 50.5312 143.444 50.5312C135.342 50.5312 128.774 57.0994 128.774 65.2017C128.774 73.304 135.342 79.8722 143.444 79.8722Z"
        fill="#05603A"
      />
      <path
        d="M139.465 72.6464L154.311 101.611L43.3778 133.846L27.176 138.554H27.1744L16.2998 117.335L29.5212 106.813L120.052 34.7743L134.898 63.7386L139.465 72.6464Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.373 34.7743L139.897 72.6772L154.854 101.717L27.4575 138.554H27.3056L16.2998 117.184L120.373 34.7743ZM120.24 35.3897L16.806 117.293L27.5302 138.117L154.276 101.468L134.975 63.9962L120.24 35.3897Z"
        fill="#05603A"
      />
      <path
        d="M42.2517 130.684C42.7391 131.636 43.0763 132.627 43.2689 133.629C44.1718 138.299 41.9593 143.197 37.5335 145.483C35.5551 146.508 33.4106 146.865 31.3515 146.64L27.0847 138.325H27.0831L16.2204 117.153L11.9536 108.838C12.966 107.026 14.5033 105.482 16.4825 104.459C19.1704 103.069 22.1635 102.905 24.8307 103.762C26.5573 104.317 28.1466 105.299 29.4282 106.655C30.129 107.392 30.737 108.243 31.2252 109.195L42.2517 130.684Z"
        fill="#6CE9A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0443 104.145C22.4159 103.311 19.467 103.471 16.8184 104.823C14.9022 105.801 13.4068 107.268 12.4091 108.99L16.6418 117.134L27.4883 138.004H27.4899L31.7786 146.255C33.7746 146.451 35.8475 146.098 37.7628 145.119L37.7629 145.119C42.1235 142.895 44.3039 138.131 43.4142 133.587L43.4142 133.587C43.2245 132.613 42.8924 131.65 42.4119 130.723M42.4118 130.723L31.3451 109.431L31.345 109.43C30.8639 108.503 30.2648 107.677 29.5746 106.959L29.5743 106.959C28.3117 105.64 26.7459 104.685 25.0443 104.145M16.6348 104.469C19.3818 103.067 22.441 102.902 25.1664 103.766C26.9308 104.326 28.555 105.317 29.865 106.685C30.5814 107.43 31.2028 108.288 31.7016 109.249C31.7016 109.249 31.7016 109.249 31.7016 109.249L42.7684 130.541L42.7685 130.541C43.2663 131.501 43.611 132.501 43.8078 133.512C44.7305 138.223 42.4699 143.165 37.9466 145.472C35.9249 146.506 33.7328 146.867 31.6283 146.639L31.5211 146.628L27.2454 138.402H27.2438L16.2854 117.316L11.9536 108.982L12.0063 108.889C13.0411 107.06 14.6125 105.502 16.6348 104.469Z"
        fill="#05603A"
      />
      <path
        d="M160.873 107.238L160.872 107.239C158.922 108.249 156.752 108.368 154.815 107.745C152.881 107.122 151.181 105.756 150.177 103.796L116.073 37.1851C114.065 33.2641 115.598 28.4471 119.496 26.4259H119.497L119.556 26.3953C121.49 25.4125 123.636 25.3031 125.552 25.92C127.468 26.5369 129.152 27.8817 130.159 29.8104L164.294 96.4791C166.304 100.402 164.772 105.217 160.873 107.238Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.317 26.7696C119.317 26.7697 119.317 26.7698 119.317 26.7699L119.215 26.8218H119.215C115.435 28.7955 113.956 33.4477 115.911 37.2371L150.112 103.525C151.094 105.427 152.756 106.752 154.647 107.356C156.54 107.961 158.662 107.845 160.567 106.865L160.568 106.865L160.569 106.865M119.317 26.7696C121.208 25.8164 123.305 25.7103 125.178 26.3088L119.317 26.7696ZM119.136 26.4127C121.124 25.4099 123.331 25.2984 125.3 25.9277C127.27 26.5569 129.001 27.929 130.037 29.8967L130.037 29.8974L130.038 29.8982L164.27 96.2439C166.336 100.246 164.761 105.158 160.753 107.22L160.752 107.221L160.751 107.221M125.178 26.3088C127.051 26.9073 128.697 28.2114 129.682 30.0821L125.178 26.3088ZM129.682 30.0821C129.682 30.0823 129.682 30.0826 129.682 30.0829L129.682 30.0821ZM129.682 30.0829L163.913 96.4271C165.878 100.232 164.38 104.903 160.57 106.864M119.136 26.4127L119.135 26.413L119.136 26.4127ZM119.135 26.413L119.135 26.4133L119.135 26.413ZM119.135 26.4133L119.118 26.4216L119.135 26.4133ZM119.118 26.4216H119.117L119.074 26.4438C115.066 28.5058 113.49 33.4201 115.554 37.4203L149.756 103.708C150.788 105.708 152.536 107.102 154.524 107.737C156.515 108.373 158.746 108.251 160.75 107.222"
        fill="#05603A"
      />
      <path
        d="M160.826 107.04L118.994 26.4237L119.053 26.3933C121.009 25.4128 123.178 25.3037 125.115 25.9191C127.053 26.5345 128.755 27.876 129.774 29.8L164.285 96.3072C166.316 100.22 164.767 105.024 160.826 107.04Z"
        fill="#6CE9A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.534 26.7139C121.388 25.8132 123.432 25.7236 125.262 26.3114C127.131 26.9118 128.773 28.2203 129.756 30.0971C129.756 30.0974 129.756 30.0977 129.757 30.098L163.916 96.6625C165.845 100.421 164.428 105.021 160.755 107.04L119.534 26.7139ZM119.232 26.4156C121.217 25.4095 123.419 25.2976 125.383 25.9289C127.349 26.5603 129.077 27.937 130.111 29.9112L130.111 29.9119L130.111 29.9126L164.272 96.4786C166.333 100.494 164.761 105.423 160.762 107.492L160.584 107.584L118.994 26.5385L119.232 26.4159L119.232 26.4156Z"
        fill="#05603A"
      />
      <path
        d="M106.497 18.4795V33.5126C106.497 37.8475 102.973 41.3602 98.6251 41.3602H62.299L53.2484 51.075L44.197 41.3602H7.87089C3.52321 41.3602 0 37.8475 0 33.5126V7.8484C0 3.51359 3.52321 0 7.87089 0H87.9624L106.497 18.4795Z"
        fill="#F1F2F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.04111 0.400202C3.82009 0.400202 0.399499 3.82756 0.399499 8.05607V33.7451C0.399499 37.9735 3.82006 41.4001 8.04111 41.4001H44.318L53.2484 51.0303L62.178 41.4001H98.4549C102.676 41.4001 106.097 37.9735 106.097 33.7451V18.7803L87.7495 0.400202H8.04111ZM0 8.05607C0 3.60659 3.5994 0 8.04111 0H87.915L106.497 18.6146V33.7451C106.497 38.1946 102.897 41.8003 98.4549 41.8003H62.3522L53.2484 51.6184L44.1438 41.8003H8.04111C3.59943 41.8003 0 38.1946 0 33.7451V8.05607Z"
        fill="#05603A"
      />
      <path
        d="M106.497 18.5575L94.1433 19.0128C90.4101 19.1502 87.3466 16.0867 87.4839 12.3535L87.9392 0L106.497 18.5575Z"
        fill="#05603A"
      />
      <path
        d="M76.3402 29.3406C80.0913 29.3406 83.1321 26.2998 83.1321 22.5487C83.1321 18.7977 80.0913 15.7568 76.3402 15.7568C72.5892 15.7568 69.5483 18.7977 69.5483 22.5487C69.5483 26.2998 72.5892 29.3406 76.3402 29.3406Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.0684 15.6279C72.3877 15.6279 69.4039 18.7265 69.4039 22.5489C69.4039 26.3712 72.3877 29.4698 76.0684 29.4698C79.7492 29.4698 82.733 26.3712 82.733 22.5489C82.733 18.7265 79.7492 15.6279 76.0684 15.6279ZM69.0049 22.5489C69.0049 18.4977 72.1673 15.2136 76.0684 15.2136C79.9695 15.2136 83.132 18.4977 83.132 22.5489C83.132 26.6 79.9695 29.8841 76.0684 29.8841C72.1673 29.8841 69.0049 26.6 69.0049 22.5489Z"
        fill="#05603A"
      />
      <path
        d="M53.5199 29.3406C57.271 29.3406 60.3118 26.2998 60.3118 22.5487C60.3118 18.7977 57.271 15.7568 53.5199 15.7568C49.7689 15.7568 46.728 18.7977 46.728 22.5487C46.728 26.2998 49.7689 29.3406 53.5199 29.3406Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2481 15.6279C49.5674 15.6279 46.5836 18.7265 46.5836 22.5489C46.5836 26.3712 49.5674 29.4698 53.2481 29.4698C56.9289 29.4698 59.9127 26.3712 59.9127 22.5489C59.9127 18.7265 56.9289 15.6279 53.2481 15.6279ZM46.1846 22.5489C46.1846 18.4977 49.347 15.2136 53.2481 15.2136C57.1492 15.2136 60.3117 18.4977 60.3117 22.5489C60.3117 26.6 57.1492 29.8841 53.2481 29.8841C49.347 29.8841 46.1846 26.6 46.1846 22.5489Z"
        fill="#05603A"
      />
      <path
        d="M30.4273 29.3406C34.3284 29.3406 37.4909 26.2998 37.4909 22.5487C37.4909 18.7977 34.3284 15.7568 30.4273 15.7568C26.5262 15.7568 23.3638 18.7977 23.3638 22.5487C23.3638 26.2998 26.5262 29.3406 30.4273 29.3406Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4273 15.6279C26.7466 15.6279 23.7628 18.7265 23.7628 22.5489C23.7628 26.3712 26.7466 29.4698 30.4273 29.4698C34.1081 29.4698 37.0919 26.3712 37.0919 22.5489C37.0919 18.7265 34.1081 15.6279 30.4273 15.6279ZM23.3638 22.5489C23.3638 18.4977 26.5262 15.2136 30.4273 15.2136C34.3284 15.2136 37.4909 18.4977 37.4909 22.5489C37.4909 26.6 34.3284 29.8841 30.4273 29.8841C26.5262 29.8841 23.3638 26.6 23.3638 22.5489Z"
        fill="#05603A"
      />
      <path
        d="M149.421 131.491H137.468V116.975C137.468 113.589 140.143 110.844 143.445 110.844C146.745 110.844 149.421 113.589 149.421 116.975V131.491Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.716 111.243C140.487 111.243 137.869 113.846 137.869 117.056V131.092H149.564V117.056C149.564 113.845 146.945 111.243 143.716 111.243ZM137.468 117.056C137.468 113.626 140.265 110.844 143.716 110.844C147.167 110.844 149.965 113.625 149.965 117.056V131.491H137.468V117.056Z"
        fill="#05603A"
      />
      <path
        d="M143.445 195.606C149.146 195.606 153.768 190.984 153.768 185.283C153.768 179.581 149.146 174.959 143.445 174.959C137.743 174.959 133.121 179.581 133.121 185.283C133.121 190.984 137.743 195.606 143.445 195.606Z"
        fill="#05603A"
      />
      <path
        d="M170.069 181.479H116.821V146.421C116.821 131.574 128.741 119.537 143.445 119.537C158.149 119.537 170.069 131.574 170.069 146.421V181.479Z"
        fill="#F1F2F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.445 119.936C128.663 119.936 116.68 131.806 116.68 146.447V181.081H170.21V146.447C170.21 131.806 158.227 119.936 143.445 119.936ZM116.277 146.447C116.277 131.585 128.441 119.537 143.445 119.537C158.449 119.537 170.612 131.585 170.612 146.447V181.479H116.277V146.447Z"
        fill="#05603A"
      />
      <path
        d="M178.763 186.37H108.127C108.127 180.968 112.55 176.589 118.007 176.589H168.881C174.339 176.589 178.763 180.968 178.763 186.37Z"
        fill="#0DC683"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.528 186.51H178.362C178.256 181.235 173.988 176.992 168.738 176.992H118.151C112.901 176.992 108.634 181.235 108.528 186.51ZM108.127 186.712C108.127 181.121 112.615 176.589 118.151 176.589H168.738C174.275 176.589 178.763 181.121 178.763 186.712V186.913H108.127V186.712Z"
        fill="#05603A"
      />
    </svg>
  )
}

export default NotifIcon
