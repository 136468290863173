import React from "react"

const UserIcon = () => {
  return (
    <svg
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9415_5769)">
        <rect y="0.5" width="47" height="47" rx="16" fill="#F2F4F7" />
        <path
          d="M42 44.4956C41.6073 44.8872 41.2027 45.2667 40.7858 45.6328C39.9643 46.3563 39.0978 47.0266 38.1918 47.6395C34.1181 50.3965 29.2411 52 24 52C18.7589 52 13.8819 50.3965 9.80825 47.6395C8.90224 47.0266 8.03568 46.3563 7.2142 45.6328C6.79726 45.2667 6.39271 44.8872 6 44.4956C6.97418 41.3916 9.35637 38.8757 12.4986 37.8561L20.1495 35.372V34.0749C19.1905 33.4893 18.3336 32.8196 17.627 32.3289C16.3125 31.4171 15.4279 29.9879 15.1665 28.3803L14.7586 25.8707C13.4807 25.8707 12.4479 24.8088 12.4479 23.495C12.4479 22.5182 13.0181 21.6806 13.8362 21.3162C13.1218 19.5939 12.5978 17.7685 12.2828 15.8967C12.0828 14.7109 11.9701 13.4601 12.3088 12.3166C12.6468 11.173 13.5427 10.1696 14.5997 10.1499L14.7383 10.1598C15.4054 9.08865 16.4477 8.38304 17.5419 7.97521C18.6361 7.56679 19.7912 7.42834 20.9355 7.29278C21.3829 7.24006 21.8325 7.18619 22.2832 7.13984C24.3617 6.93419 26.625 6.88437 28.7503 7.49264C30.8761 8.1015 32.9299 9.46636 34.0122 11.6423C34.872 13.3686 35.0461 15.4512 34.8297 17.4215C34.6866 18.729 34.3818 20.0017 34.024 21.2582C34.916 21.5867 35.5521 22.4632 35.5521 23.495C35.5521 24.8088 34.5204 25.8707 33.2414 25.8707L32.8335 28.3803C32.5721 29.9879 31.688 31.4171 30.3736 32.3289C29.6659 32.8196 28.81 33.4893 27.8511 34.0749V35.372L35.5014 37.8561C38.6436 38.8763 41.0258 41.3922 42 44.4956Z"
          fill="#98A2B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_9415_5769">
          <rect y="0.5" width="47" height="47" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserIcon
