import { AxiosError } from "axios"
import { messageTypes } from "../constants/messageTypes"
import { showMessage } from "./showMessage"

export const errorHandler = (err: AxiosError<{ messages?: string[] }>) => {
  const messages = err?.response?.data?.messages

  if (err?.response?.status !== 404 && err?.response?.status !== 500) {
    if (messages) {
      messages.map((message) => showMessage(message, messageTypes.ERROR))
    } else {
      showMessage("Xatolik", messageTypes.ERROR)
    }
  }
}
