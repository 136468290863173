import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { RU, UZS } from "../../utils/constants/locales"
import { sliceNames } from "../../utils/constants/sliceNames"

type initialState = {
  lang: string
  currency: string
  usdValue: number
}

const initialState: initialState = {
  lang: RU,
  currency: UZS,
  usdValue: 0
}

const localeReducer = createSlice({
  name: sliceNames.LOCALE,
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
    changeCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload
    },
    changeUsdValue: (state, action: PayloadAction<number>) => {
      state.usdValue = action.payload
    },
  },
})

export default localeReducer.reducer
export const localeReducerActions = localeReducer.actions
