import React from "react"

const MenuFacebookIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0004 17.2059C35.0004 25.8956 28.5609 33.0796 20.1955 34.2454C19.4133 34.3539 18.6131 34.4105 17.8008 34.4105C16.8631 34.4105 15.9423 34.3358 15.0457 34.1911C6.85503 32.8722 0.599976 25.769 0.599976 17.2059C0.599976 7.70358 8.30129 0 17.7996 0C27.2979 0 35.0004 7.70358 35.0004 17.2059Z"
        fill="#1877F2"
      />
      <path
        d="M20.1939 13.8149V17.563H24.8292L24.0952 22.612H20.1939V34.2445C19.4117 34.353 18.6115 34.4096 17.7992 34.4096C16.8615 34.4096 15.9407 34.3349 15.0441 34.1902V22.612H10.7692V17.563H15.0441V12.9771C15.0441 10.1319 17.3496 7.82446 20.1951 7.82446V7.82687C20.2036 7.82687 20.2108 7.82446 20.2192 7.82446H24.8304V12.191H21.8174C20.9219 12.191 20.1951 12.918 20.1951 13.8137L20.1939 13.8149Z"
        fill="white"
      />
    </svg>
  )
}

export default MenuFacebookIcon
