import React from "react"

function FavouriteIcon(svg: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...svg}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7 2.58333C12.1917 2.58333 10.8417 3.31667 10 4.44167C9.15835 3.31667 7.80835 2.58333 6.30002 2.58333C3.74169 2.58333 1.66669 4.66667 1.66669 7.24167C1.66669 8.23333 1.82502 9.15 2.10002 10C3.41669 14.1667 7.47502 16.6583 9.48335 17.3417C9.76669 17.4417 10.2334 17.4417 10.5167 17.3417C12.525 16.6583 16.5834 14.1667 17.9 10C18.175 9.15 18.3334 8.23333 18.3334 7.24167C18.3334 4.66667 16.2584 2.58333 13.7 2.58333Z"
        fill="#7C7C7C"
      />
    </svg>
  )
}

export default FavouriteIcon
