import React from "react"
import { useRouter } from "next/router"
import { stringify, UrlObject } from "query-string"

const CustomLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: React.ReactNode
    key?: React.Key
    href: string | (UrlObject & string)
  }
> = (props) => {
  const { href, className, children, ...rest } = props
  const router = useRouter()

  const onNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    router.push(href)
  }

  return (
    <a
      {...rest}
      href={typeof href === "string" ? href:`?${stringify((href as UrlObject).query!)}`}
      onClick={onNavigate}
      className={className ?? "hover:pointer-events-auto"}
    >
      {children}
    </a>
  )
}

export default CustomLink
