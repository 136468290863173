import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon"
import QuizIcon from "assets/icons/QuizIcon"
import Link from "next/link"
import { initialRooms } from "pages/main/components/main-filter/mainFilterItems"
import React, { useEffect, useState } from "react"

import districts from "../../pages/main-layout/components/footer-filter/districts.json"
import { Button, Form, Input, message } from "antd"
import { useGetNewPaymentMethods } from "pages/apartments/services/queries"
import { PHONE_MASK } from "utils/constants/inputMasks"
import { InputMaskCopy } from "common/inputMask/InputMaskCopy"
import { useRouter } from "next/router"
import UysotIcon from "assets/icons/UysotIcon"
import { useSendApplication } from "pages/residential-complexes/services/mutation"
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel"
import ComplexCard from "common/common-card/complex/ComplexCard"
import { viewTypes } from "utils/constants/viewTypes"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import { layoutLang } from "utils/language/layout"
import { SendAppModel } from "pages/residential-complexes/utils/models/SendAppModel"
import { isBrowser } from "utils/helpers/isBrowser"

const prePayment = [
  {
    id: 1,
    value: "50",
    label: "0-50000000",
    type: "mln",
  },
  {
    id: 2,
    value: "50-100",
    label: "50000000-100000000",
    type: "mln",
  },
  {
    id: 2,
    value: "100-200",
    label: "100000000-200000000",
    type: "mln",
  },
  {
    id: 3,
    value: "200-300",
    label: "200000000-300000000",
    type: "mln",
  },
  {
    id: 4,
    value: "300-400",
    label: "300000000-400000000",
    type: "mln",
  },
  {
    id: 5,
    value: "400-500",
    label: "400000000-500000000",
    type: "mln",
  },
  {
    id: 6,
    value: "500+",
    label: "500000000",
    type: "mln",
  },
]
const maxPrices = [
  {
    id: 1,
    value: "200",
    label: "0-200000000",
    type: "mln",
  },
  {
    id: 2,
    value: "200-400",
    label: "200000000-400000000",
    type: "mln",
  },
  {
    id: 3,
    value: "400-600",
    label: "400000000-600000000",
    type: "mln",
  },
  {
    id: 4,
    value: "600-800",
    label: "600000000-800000000",
    type: "mln",
  },
  {
    id: 5,
    value: "800 mln - 1 mlrd",
    label: "800000000-1000000000",
    type: "mln",
  },
  {
    id: 6,
    value: "+1",
    label: "1000000000",
    type: "mlrd",
  },
]
const minPrices = [
  {
    id: 1,
    value: "5",
    label: "0-5000000",
    type: "mln",
  },
  {
    id: 2,
    value: "5-7",
    label: "5000000-7000000",
    type: "mln",
  },
  {
    id: 3,
    value: "7-10",
    label: "7000000-10000000",
    type: "mln",
  },
  {
    id: 4,
    value: "10+",
    label: "10000000",
    type: "mln",
  },
]

const itemStyle =
  "border border-solid border-[#EAECF0] rounded-[10px] px-3.5 py-2.5 text-[#101828] text-base font-medium cursor-pointer hover:!border-[#0DC683] hover:bg-[#EEFBF6] transition-all duration-300 flex items-center justify-between"

const Quiz = () => {
  const sendApp = useSendApplication()
  const { data } = useGetNewPaymentMethods()
  const { locale } = useRouter()
  const [page, setPage] = useState<number>(1)
  const [quizData, setQuizData] = useState<NewResidentalComplexModel[]>([])
  const [quizState, setQuizState] = useState<{
    city_id?: number
    payment_method_id?: number
    monthly_amount?: string
    name?: string
    phone?: string
  }>({
    name: "",
    phone: "",
  })

  const [roomsCount, setRoomsCount] = useState<number[]>([])
  const [total_amount, setTotalAmount] = useState<string>("")
  const [borderStatus, setBorderStatus] = useState({
    name: false,
    phone: false,
  })
  const [status, setStatus] = useState(false)

  const getPhone = isBrowser() && localStorage.getItem("phone")

  useEffect(() => {
    setQuizState((state) => ({
      ...state,
      phone: String(getPhone)?.replace(/\D/g, ""),
    }))
  }, [getPhone])

  const handleChangeRoomsCount = (roomCount: number) => {
    if (roomsCount?.includes(roomCount)) {
      const newRoomCount = roomsCount?.filter((item) => item !== roomCount)
      setRoomsCount(newRoomCount)
    } else {
      setRoomsCount((state) => [...state, roomCount])
    }
  }

  const handleSendApp = () => {
    const sendData: SendAppModel =
      quizState?.payment_method_id === 2
        ? {
            city_id: Number(quizState?.city_id),
            diapason_monthly_amount: String(
              minPrices?.find(
                (item) => quizState?.monthly_amount === item?.value
              )?.label
            ),
            diapason_total_amount: String(
              finalPayment?.find((item) => item?.value === total_amount)?.label
            ),
            name: String(quizState?.name),
            payment_method_id: Number(quizState?.payment_method_id),
            phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
            rooms_count: roomsCount?.join(","),
          }
        : quizState?.payment_method_id !== -1
        ? {
            city_id: Number(quizState?.city_id),
            diapason_total_amount: String(
              finalPayment?.find((item) => item?.value === total_amount)?.label
            ),
            name: String(quizState?.name),
            payment_method_id: Number(quizState?.payment_method_id),
            phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
            rooms_count: roomsCount?.join(","),
          }
        : {
            city_id: Number(quizState?.city_id),
            diapason_total_amount: String(
              finalPayment?.find((item) => item?.value === total_amount)?.label
            ),
            name: String(quizState?.name),
            phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
            rooms_count: roomsCount?.join(","),
          }

    // eslint-disable-next-line no-empty
    if (Number(quizState?.phone?.length) > 11) {
      setBorderStatus((state) => ({ ...state, phone: false }))
    } else {
      setBorderStatus((state) => ({ ...state, phone: true }))
    }
    if (Number(quizState?.name?.length) < 1) {
      setBorderStatus((state) => ({ ...state, name: true }))
    } else {
      setBorderStatus((state) => ({ ...state, name: false }))
    }

    Number(quizState?.phone?.length) > 11 &&
      Number(quizState?.name?.length) > 0 &&
      sendApp.mutateAsync(sendData).then((res) => {
        message.success(layoutLang[String(locale)]["muvaffaqqiyatli"])
        if (res?.message?.uz === "Muvaffaqiyatli") {
          setStatus(true)
        }
        setQuizData(res?.data)
      })
  }

  const finalPaymentData = data?.concat({
    id: -1,
    isTop: false,
    name: { uz: "Boshqa", ru: "Другой" },
  })

  const finalPayment =
    quizState?.payment_method_id === 2 ? prePayment : maxPrices

  const leftSide = [
    {
      id: 1,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
              quizState?.payment_method_id === 2 ? 6 : 5
            } ${layoutLang[String(locale)]["savol"]}`}</p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {layoutLang[String(locale)]["xonadoni_qayerdan_qidiramiz"]}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5 overflow-auto h-[85%]">
              {districts?.map((item) => (
                <div
                  key={item?.id}
                  className={`${
                    quizState?.city_id === item?.id ? "bg-[#EEFBF6]" : ""
                  } ${
                    quizState?.city_id === item?.id ? "!border-[#0DC683]" : ""
                  } ${itemStyle}`}
                  onClick={() =>
                    quizState?.city_id === item?.id
                      ? setQuizState((state) => ({
                          ...state,
                          city_id: undefined,
                        }))
                      : setQuizState((state) => ({
                          ...state,
                          city_id: item?.id,
                        }))
                  }
                >
                  <span>{locale === "uz" ? item?.name : item?.name_ru}</span>
                  {item?.isTop && (
                    <div className="bg-[#0DC683] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                      {layoutLang[String(locale)]["TOP"]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <Button
              type="primary"
              onClick={() => setPage(page + 1)}
              className="h-[47px] rounded-xl flex items-center justify-center w-full text-base"
              disabled={!quizState?.city_id}
            >
              {layoutLang[String(locale)]["keyingisi"]}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
              quizState?.payment_method_id === 2 ? 6 : 5
            } ${layoutLang[String(locale)]["savol"]}`}</p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {
                layoutLang[String(locale)][
                  "siz_nechi_xonalik_kvartirani_qidiryapsiz"
                ]
              }
            </h2>
            <p className="text-[#98A2B3] text-base font-medium">
              {layoutLang[String(locale)]["multi_select_rooms"]}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
              {initialRooms?.map((item) => (
                <div
                  key={item?.key}
                  className={`${
                    roomsCount?.includes(Number(item?.key))
                      ? "bg-[#EEFBF6]"
                      : ""
                  } ${
                    roomsCount?.includes(Number(item?.key))
                      ? "!border-[#0DC683]"
                      : ""
                  } ${itemStyle}`}
                  onClick={() => handleChangeRoomsCount(Number(item?.key))}
                >
                  <span>{`${item?.label} ${
                    item?.key === "0"
                      ? ""
                      : layoutLang[String(locale)]["xonalik"]
                  }`}</span>
                  {item?.isTop && (
                    <div className="bg-[#0DC683] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                      {layoutLang[String(locale)]["TOP"]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <div
              onClick={() => setPage(page - 1)}
              className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
            >
              <JKArrowLeftIcon fill="#475467" width={18} height={14} />
              <span className="text-base text-[#475467] font-medium">
                {layoutLang[String(locale)]["ortga"]}
              </span>
            </div>
            <Button
              type="primary"
              onClick={() => setPage(page + 1)}
              className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
              disabled={roomsCount?.length === 0}
            >
              {layoutLang[String(locale)]["keyingisi"]}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
              quizState?.payment_method_id === 2 ? 6 : 5
            } ${layoutLang[String(locale)]["savol"]}`}</p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {layoutLang[String(locale)]["uy_joy_xarid_qilish_usuli"]}
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
              {finalPaymentData?.map((item) => (
                <div
                  key={item?.id}
                  className={`${
                    quizState?.payment_method_id === item?.id
                      ? "bg-[#EEFBF6]"
                      : ""
                  } ${
                    quizState?.payment_method_id === item?.id
                      ? "!border-[#0DC683]"
                      : ""
                  } ${itemStyle}`}
                  onClick={() =>
                    item?.id === quizState?.payment_method_id
                      ? setQuizState((state) => ({
                          ...state,
                          payment_method_id: undefined,
                        }))
                      : setQuizState((state) => ({
                          ...state,
                          payment_method_id: item?.id,
                        }))
                  }
                >
                  <span>{item?.name[locale as keyof typeof item.name]}</span>
                  {item?.isTop && (
                    <div className="bg-[#0DC683] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                      {layoutLang[String(locale)]["TOP"]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <div
              onClick={() => setPage(page - 1)}
              className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
            >
              <JKArrowLeftIcon fill="#475467" width={18} height={14} />
              <span className="text-base text-[#475467] font-medium">
                {layoutLang[String(locale)]["ortga"]}
              </span>
            </div>
            <Button
              type="primary"
              onClick={() => setPage(page + 1)}
              className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
              disabled={!quizState?.payment_method_id}
            >
              {layoutLang[String(locale)]["keyingisi"]}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
              quizState?.payment_method_id === 2 ? 6 : 5
            } ${layoutLang[String(locale)]["savol"]}`}</p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {quizState?.payment_method_id === 2
                ? layoutLang[String(locale)]["boshlangich_tolov_oraligi"]
                : layoutLang[String(locale)]["narx_oraligi"]}
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5 h-[70%] overflow-auto">
              {finalPayment?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className={`${
                      item?.value === total_amount ? "bg-[#EEFBF6]" : ""
                    } ${
                      item?.value === total_amount ? "!border-[#0DC683]" : ""
                    } ${itemStyle}`}
                    onClick={() => {
                      item?.value === total_amount
                        ? setTotalAmount("")
                        : setTotalAmount(item?.value)
                    }}
                  >
                    {`${
                      locale === "ru" &&
                      (item?.value === "50" || item?.value === "200")
                        ? "до"
                        : ""
                    } ${
                      item?.id === 5 && quizState?.payment_method_id !== 2
                        ? item?.value
                            ?.replace("mln", layoutLang[String(locale)]["mln"])
                            ?.replace(
                              "mlrd",
                              layoutLang[String(locale)]["mlrd"]
                            )
                        : item?.value
                    } ${
                      item?.id === 5 && quizState?.payment_method_id !== 2
                        ? ""
                        : layoutLang[String(locale)][item?.type]
                    } ${
                      locale === "uz" &&
                      (item?.value === "50" || item?.value === "200")
                        ? "gacha"
                        : ""
                    }`}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <div
              onClick={() => {
                setPage(page - 1)
                setQuizState((state) => ({
                  ...state,
                  monthly_amount: "",
                }))
                setTotalAmount("")
              }}
              className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
            >
              <JKArrowLeftIcon fill="#475467" width={18} height={14} />
              <span className="text-base text-[#475467] font-medium">
                {layoutLang[String(locale)]["ortga"]}
              </span>
            </div>
            <Button
              type="primary"
              onClick={() =>
                quizState?.payment_method_id === 2
                  ? setPage(page + 1)
                  : setPage(page + 2)
              }
              className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
              disabled={!total_amount}
            >
              {layoutLang[String(locale)]["keyingisi"]}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 5,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
              quizState?.payment_method_id === 2 ? 6 : 5
            } ${layoutLang[String(locale)]["savol"]}`}</p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {layoutLang[String(locale)]["oylik_tolov_oraligi"]}
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
              {minPrices?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className={`${
                      item?.value === quizState?.monthly_amount
                        ? "bg-[#EEFBF6]"
                        : ""
                    } ${
                      item?.value === quizState?.monthly_amount
                        ? "!border-[#0DC683]"
                        : ""
                    } ${itemStyle}`}
                    onClick={() =>
                      item?.value === quizState?.monthly_amount
                        ? setQuizState((state) => ({
                            ...state,
                            monthly_amount: "",
                          }))
                        : setQuizState((state) => ({
                            ...state,
                            monthly_amount: item?.value,
                          }))
                    }
                  >
                    {`${locale === "ru" && item?.value === "5" ? "до" : ""} ${
                      item?.value
                    } ${
                      item?.id === 5 && quizState?.payment_method_id !== 2
                        ? ""
                        : layoutLang[String(locale)][item?.type]
                    } ${locale === "uz" && item?.value === "5" ? "gacha" : ""}`}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <div
              onClick={() => setPage(page - 1)}
              className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
            >
              <JKArrowLeftIcon fill="#475467" width={18} height={14} />
              <span className="text-base text-[#475467] font-medium">
                {layoutLang[String(locale)]["ortga"]}
              </span>
            </div>
            <Button
              type="primary"
              onClick={() => setPage(page + 1)}
              className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
              disabled={!quizState?.monthly_amount}
            >
              {layoutLang[String(locale)]["keyingisi"]}
            </Button>
          </div>
        </div>
      ),
    },

    {
      id: 6,
      component: (
        <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
          <div className="min-h-[305px]">
            <p className="text-[#98A2B3] text-base font-medium mb-2">
              {layoutLang[String(locale)]["oxirgi_savol"]}
            </p>
            <h2 className="text-[#344054] text-2xl font-medium">
              {layoutLang[String(locale)]["ism_telefon_raqam"]}
            </h2>
            <p className="text-[#98A2B3] text-base font-medium">
              {layoutLang[String(locale)]["ism_telefon_raqam_text"]}
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-[#344054] text-sm font-medium">
                  {layoutLang[String(locale)]["FISH"]}
                </span>
                <Input
                  className={`h-11 rounded-xl bg-[#F2F4F7] ${
                    borderStatus?.name ? "!border-red-500" : "border-none"
                  } !shadow-none`}
                  placeholder={layoutLang[String(locale)]["FISH"]}
                  value={quizState?.name}
                  onChange={(e) => {
                    setQuizState((state) => ({
                      ...state,
                      name: e.target.value,
                    }))
                    if (e?.target?.value?.length > 0) {
                      setBorderStatus((state) => ({ ...state, name: false }))
                    } else {
                      setBorderStatus((state) => ({ ...state, name: true }))
                    }
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#344054] text-sm font-medium">
                  {layoutLang[String(locale)]["Номер телефона"]}
                </span>
                {InputMaskCopy({
                  mask: PHONE_MASK,
                  placeholder: "+998 00 000 00 00",
                  type: "quiz",
                  borderStatus: borderStatus?.phone,
                  onChange: (e) => {
                    setQuizState((state) => ({
                      ...state,
                      phone: e.target.value?.replace(/\D/g, ""),
                    }))

                    if (e?.target?.value?.length > 18) {
                      setBorderStatus((state) => ({ ...state, phone: false }))
                    } else {
                      setBorderStatus((state) => ({ ...state, phone: true }))
                    }
                  },
                  value: quizState?.phone,
                })}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 w-full mt-[68px]">
            <div
              onClick={() =>
                quizState?.payment_method_id === 2
                  ? setPage(page - 1)
                  : setPage(page - 2)
              }
              className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
            >
              <JKArrowLeftIcon fill="#475467" width={18} height={14} />
              <span className="text-base text-[#475467] font-medium">
                {layoutLang[String(locale)]["ortga"]}
              </span>
            </div>
            <Button
              type="primary"
              className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
              onClick={handleSendApp}
              loading={sendApp?.isLoading}
            >
              {layoutLang[String(locale)]["ariza_yuborish"]}
            </Button>
          </div>
        </div>
      ),
    },
  ]

  const filterLeftSide =
    quizState?.payment_method_id === 2
      ? leftSide
      : leftSide?.filter((item) => item?.id !== 5)

  const currentComponent = filterLeftSide?.find(
    (item) => item?.id === page
  )?.component

  const currentDistrict = districts?.find(
    (item) => item?.id === quizState?.city_id
  )?.url_name

  const moreLink = `/${currentDistrict}/kvartiri?city_id=${quizState?.city_id}`

  return (
    <div className="bg-white flex items-center">
      {!status ? (
        <div className="max-w-[1230px] mx-auto flex justify-between w-full items-center flex-wrap-reverse gap-10 h-screen sm:px-0 px-5 relative pt-[72px] xl:pt-0">
          {currentComponent}
          <div className="w-[430px] hidden sm:block">
            <QuizIcon />
            <p className="text-base">
              <b>{layoutLang[String(locale)]["tanlash_qiyinmi"]}</b>
              <Link
                href={"/gorod-tashkent/novostroyki"}
                className="text-[#0DC683]"
              >
                «Uysot»
              </Link>
              {layoutLang[String(locale)]["mos_uy"]}
            </p>
          </div>
          <div className="absolute top-6 left-5 sm:left-0">
            <Link
              href={"/gorod-tashkent/novostroyki"}
              className="cursor-pointer flex items-center gap-5"
            >
              <JKArrowLeftIcon fill="#475467" />
              <UysotIcon />
            </Link>
          </div>
        </div>
      ) : (
        <div className="max-w-[1230px] mx-auto h-full w-full flex items-center relative">
          <div className="absolute top-6 left-5 sm:left-0 flex items-center gap-5">
            <Link
              href={"/gorod-tashkent/novostroyki"}
              className="cursor-pointer"
            >
              <JKArrowLeftIcon fill="#475467" />
            </Link>
            <UysotIcon />
          </div>
          <div className="flex flex-col gap-6 w-full pt-[130px] px-5 sm:px-0 pb-5 sm:pb-0 min-h-screen">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#344054] text-2xl font-medium">
                {layoutLang[String(locale)]["siz_bilan_boglanamiz"]}
              </h1>
              <p className="text-[#98A2B3] text-base font-medium">
                {layoutLang[String(locale)]["uysotdan_taklif"]}
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 w-full flex-wrap">
              {quizData?.slice(0, 3)?.map((item) => (
                <NonSSRWrapper key={item?.id}>
                  <ComplexCard
                    data={item}
                    key={item?.id}
                    viewType={viewTypes.GRID}
                  />
                </NonSSRWrapper>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <Link
                href={moreLink}
                className="border border-[#ebebeb] border-solid h-[43px] flex items-center justify-center px-8 rounded-md text-[#0dc683] text-base font-medium"
              >
                {layoutLang[String(locale)]["Hammasi"]}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Quiz
