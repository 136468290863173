export enum imageLayouts {
  FILL = "fill",
  FIXED = "fixed",
  INTRINSIC = "intrinsic",
  RESPONSIVE = "responsive",
}

export enum imageObjectFits {
  COVER = "cover",
  CONTAIN = "contain",
}

export enum imageLoadings {
  LAZY = "lazy",
}
