import React from "react"

interface IProps {
  stroke?: string
}

const MapIcon: React.FC<IProps> = ({ stroke = "#0DC683" }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50033 15.5003L1.66699 18.8337V5.50033L7.50033 2.16699M7.50033 15.5003L13.3337 18.8337M7.50033 15.5003V2.16699M13.3337 18.8337L18.3337 15.5003V2.16699L13.3337 5.50033M13.3337 18.8337V5.50033M13.3337 5.50033L7.50033 2.16699"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MapIcon
