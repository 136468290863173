import axios from "axios"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, Messaging } from "firebase/messaging"
import { isBrowser } from "utils/helpers/isBrowser"

const firebaseConfig = {
  apiKey: "AIzaSyDtEloM6yCEpn9xtXoWnKh64p9c9yiwq0U",
  authDomain: "uysotuz-marketpleys.firebaseapp.com",
  projectId: "uysotuz-marketpleys",
  storageBucket: "uysotuz-marketpleys.appspot.com",
  messagingSenderId: "763730302416",
  appId: "1:763730302416:web:ed5a3f62999eb1d032d5d5",
  measurementId: "G-WN2290V66H",
}

initializeApp(firebaseConfig)

let messaging: Messaging
if (typeof window !== "undefined") {
  messaging = getMessaging()
}

export const sendRequest = (setToken: (token: string) => void) => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey:
          "BK-kfcTSdyw1xhXe8ApXBN5lwsDXgvzxwz_vBKQXMxtHQVubqfn_dqi5sTAe1xYywx8RHPl7iywkVqkEI_HLStk",
      })
        .then((currentToken) => {
          if (currentToken) {
            const userData = {
              device_token: currentToken,
              device_type: "web",
            }

            axios
              .post(
                "https://marble.oci.softex.uz/main/notification/create-device-token",
                userData,
                { withCredentials: true }
              )
              .then((response) => {
                console.log(response)
              })
            setToken(currentToken)

            localStorage.setItem("deviceToken", currentToken)
          } else {
            console.log("Failed to generate the registration token.")
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          )
        })
    }
    return undefined
  })
}
