import { Form, Modal } from "antd"
import React, { useMemo, useState } from "react"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { phoneNumberFixer } from "../../../../utils/helpers/phoneNumberFixer"
import { useLogin, useRegister } from "../../services/mutations"
import Confirmation from "./confirmation/Confirmation"
import Phone from "./phone/Phone"

import styles from "./loginModal.module.scss"
import { useRouter } from "next/router"
import { layoutLang } from "../../../../utils/language/layout"

type FormFields = {
  phone: string
  sms_code: string
}

const LoginModal: React.FC = () => {
  const { locale, query } = useRouter()
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const [step, setStep] = useState(1)
  const { setVisibleLoginModal, setIsAuth } = userReducerActions
  const register = useRegister(handleAfterSuccessRegister)
  const login = useLogin(handleAfterSuccesslogin)
  const { visibleLoginModal, bonusId } = useAppSelector(
    (state) => state.userReducer
  )

  const complexId = query?.id
    ? Number(
        query?.id?.toLocaleString()?.split("-")[
          query?.id?.toLocaleString()?.split("-")?.length - 1
        ]
      )
    : 0

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleLoginModal(false))
  }

  // handle after close
  const handleAfterClose = () => {
    step !== 1 && setStep(1)
    formInstance.resetFields()
  }

  // handle after success register
  function handleAfterSuccessRegister() {
    setStep(2)
  }

  // handle after succes login
  function handleAfterSuccesslogin() {
    handleCancel()
    formInstance.resetFields()
    dispatch(setIsAuth(true))
  }

  // on finish
  const onFinish = (fields: FormFields) => {
    if (step === 1) register.mutate({ phone: phoneNumberFixer(fields?.phone) })
    else {
      if (!login.isLoading) {
        if (bonusId !== null) {
          login
            .mutateAsync({
              phone: phoneNumberFixer(fields?.phone),
              sms_code: +fields.sms_code,
              bonus_id: bonusId,
              complex_id: complexId,
            })
            .then((data: any) => {
              localStorage.setItem("token", data?.Authorization)
              localStorage.removeItem("anonymous_user_id")
            })
        } else {
          login
            .mutateAsync({
              phone: phoneNumberFixer(fields?.phone),
              sms_code: +fields.sms_code,
            })
            .then((data: any) => {
              localStorage.setItem("token", data?.Authorization)
              localStorage.removeItem("anonymous_user_id")
            })
        }
      }
    }
  }

  return (
    <Modal
      centered
      title={null}
      footer={false}
      closable={false}
      onCancel={handleCancel}
      afterClose={handleAfterClose}
      open={visibleLoginModal}
      className={`${styles.login_modal} custom_styles_modal`}
      zIndex={1000000}
    >
      <div className="custom_styles_modal_header">
        <h1>
          {step === 1
            ? layoutLang[String(locale)]["Войти или создать личный кабинет"]
            : layoutLang[String(locale)]["Введите код из СМС"]}
        </h1>
        <CloseIcon onClick={handleCancel} />
      </div>
      <Form
        layout="vertical"
        form={formInstance}
        onFinish={onFinish}
        autoComplete="off"
        className={styles.login_modal_body}
      >
        <Phone
          isActive={step === 1}
          isLoading={register.isLoading}
          formInstance={formInstance}
        />
        <Confirmation
          isLoading={login.isLoading}
          setStep={setStep}
          step={step}
          isActive={step === 2}
          formInstance={formInstance}
        />
      </Form>
    </Modal>
  )
}

export default LoginModal
