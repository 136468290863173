import Image, { ImageProps } from 'next/image';
import Head from 'next/head';



const RichImage = (props:ImageProps) => {
  const { src, alt, width, height } = props
  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{
          __html:JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "url": src,
              "description": alt,
              "width": width,
              "height": height,
            })
        }} />
      </Head>
      <Image {...props} src={src} alt={alt} width={width} height={height} />
    </>
  )
};

export default RichImage;
