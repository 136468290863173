"use-client"
import React, { ReactNode, useEffect, useState } from "react"
import { isAndroid } from "react-device-detect"
import { cx } from "utils/cx"
import { Grid } from "antd"
import { useRouter } from "next/router"

import NonSSRWrapper from "../../common/non-ssr/NonSSRWrapper"
import { useAppSelector } from "../../hooks/reduxHooks"
import AddressModal from "./components/address-modal/AddressModal"
import Footer from "./components/footer/Footer"
import Header from "./components/header/Header"
import LoginModal from "./components/login-modal/LoginModal"
import MobileAppDownload from "./components/mobile-app-download/MobileAppDownload"
import MobileMenu from "./components/mobile-menu/MobileMenu"

import { isBrowser } from "utils/helpers/isBrowser"
import Notification from "./components/notification/Notification"
import { useCheckHasToken } from "services/useCheckHasToken"
import FooterFilter from "./components/footer-filter/FooterFilter"
import MobileFooterMenu from "./components/mobile-footer-menu/MobileFooterMenu"

import styles from "./index.module.scss"
import LangDropdown from "./components/header/bottom/LangDropdown"
import MenuCurrency from "./components/mobile-menu/menu-currency/MenuCurrency"
import NavigateButton from "./components/navigate-button/NavigateButton"
import RegionSelect from "./components/region-city/RegionCity"

type Props = {
  children: ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const { md } = Grid.useBreakpoint()
  const { userAddress } = useAppSelector((state) => state.userReducer)
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false)

  // const hasToken = isBrowser() ? localStorage.getItem("token") : ""
  // const getAnonimUserId = useCheckHasToken()

  // useEffect(() => {
  //   if (!hasToken) {
  //     getAnonimUserId.mutateAsync().then((data) => {
  //       localStorage.setItem("anonymous_user_id", data?.data?.anonymous_user_id)
  //     })
  //   }
  // }, [hasToken])

  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt")
    setIsAppSuggestionCanceled(!appSuggestion)
  }

  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus)

    const intervalId = setInterval(updateAppSuggestionStatus, 500)

    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus)
      clearInterval(intervalId)
    }
  }, [])

  const inNavigateBtn =
    (router.pathname.includes("karta") ||
      router.pathname.includes("kvartiri") ||
      router.pathname.includes("novostroyki")) &&
    !router?.query?.apartmentId &&
    !router?.query?.id

  return (
    <>
      <div className={styles.layout}>
        <div
          id="top_padding"
          className={`${styles.distance} ${
            userAddress ? styles.has_address : ""
          }`}
          style={{
            paddingBottom:
              (router?.pathname === "/[location]/kvartiri" ||
                router?.pathname === "/[location]/novostroyki") &&
              md
                ? 160
                : "",
          }}
        />
        <header className={styles.layout_header} id="app_bar">
          <Header />
        </header>
        {!md && (
          <NonSSRWrapper>
            <MobileMenu />
          </NonSSRWrapper>
        )}
        <main
          className={cx(
            styles.layout_children,
            !md &&
              isAndroid &&
              isAppSuggestionCanceled &&
              styles.layout_children_with_app_suggestion
          )}
        >
          {children}
        </main>

        <div id="footer">
          <Notification />
          <LangDropdown />
          <MenuCurrency />
          <FooterFilter />
          <MobileAppDownload />
          <Footer />
          {inNavigateBtn  && <div className="mobile_only">
            <NavigateButton />
          </div>}
          <MobileFooterMenu />
        </div>
      </div>
      <AddressModal />
      <LoginModal />
      <RegionSelect />
    </>
  )
}

export default MainLayout
