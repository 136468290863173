import ListIcon from "assets/icons/ListIcon"
import MapIcon from "assets/icons/MapIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import React from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { rootPaths } from "utils/constants/rootPaths"
import { layoutLang } from "utils/language/layout"

const NavigateButton = () => {
  const { query, push, pathname, locale } = useRouter()
  const { location, ...getQuery } = query
  const { setChangeFilterType } = favouritesReducerActions
  const dispatch = useAppDispatch()
  const { location: currentLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )
  // location
  const newLocation = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url
    : "gorod-tashkent"

  const isMap = pathname?.includes("karta")
  const handleNavigate = () => {
    isMap
      ? push({
          pathname: `/${newLocation}${
            pathname.includes("kvartiri")
              ? rootPaths.APARTMENTS
              : rootPaths.RESIDENTIAL_COMPLEXES
          }`,
          query: { ...getQuery },
        })
      : push({ pathname: rootPaths.MAP, query: getQuery })
    pathname.includes("kvartiri")
      ? dispatch(setChangeFilterType("apartment"))
      : dispatch(setChangeFilterType("complex"))
  }

  return (
    <div
      className="fixed bottom-[110px] left-4 z-[99] bg-[#00A389] h-11 px-4 rounded-xl flex items-center justify-center cursor-pointer text-white text-base font-semibold"
      onClick={handleNavigate}
    >
      {isMap ? (
        <div className="flex items-center gap-2.5">
          <ListIcon />
          <span>{layoutLang[String(locale)]["royxat"]}</span>
        </div>
      ) : (
        <div className="flex items-center gap-2.5">
          <MapIcon stroke="#fff" />
          <span>{layoutLang[String(locale)]["Xarita"]}</span>
        </div>
      )}
    </div>
  )
}

export default NavigateButton
