import React from "react"

const ShopIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="13" rx="2" fill="#6C34B4" />
      <path
        d="M10.75 9.26V8.6H4.875L5.25 7.94L11 7.4L12 3.8H4.3125L3.875 2H2V2.6H3.375L4.6875 7.64L3.875 9.2V10.1C3.875 10.58 4.3125 11 4.8125 11C5.3125 11 5.75 10.58 5.75 10.1C5.75 9.62 5.3125 9.2 4.8125 9.2H9.5V10.1C9.5 10.58 9.9375 11 10.4375 11C10.9375 11 11.375 10.58 11.375 10.1C11.375 9.68 11.125 9.38 10.75 9.26ZM4.5 4.4H11.1875L10.5 6.8L5.25 7.34L4.5 4.4Z"
        fill="white"
      />
      <path d="M4.5 4.4H11.1875L10.5 6.8L5.25 7.34L4.5 4.4Z" fill="white" />
    </svg>
  )
}

export default ShopIcon
