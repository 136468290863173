import { Checkbox, Form } from "antd"
import { useRouter } from "next/router"
import { PaymentMethodsAndClassesType } from "pages/apartments/utils/models/models"
import React from "react"

interface IProps {
  data: PaymentMethodsAndClassesType[]
  name: string
}

const FilterItem: React.FC<IProps> = ({ data, name }) => {
  const { locale } = useRouter()
  return (
    <>
      <Form.Item name={name}>
        <Checkbox.Group>
          {data?.map((item) => (
            <Checkbox
              key={item?.id}
              value={item?.value ? item?.value : item?.id}
            >
              {item?.name[locale as keyof typeof item.name]}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

export default FilterItem
