import React, { cloneElement, useEffect, useState } from "react"
import { Button, Form, Input, Modal, Select } from "antd"
import styles from "./filterDesktopModal.module.scss"
import ArrowIcon from "../../../assets/icons/ArrowIcon"
import {
  useGetRegionCity,
  useGetRegionDistricts,
} from "../../../pages/main-layout/services/queries"
import { localeFormatter } from "../../../utils/helpers/localeFormatter"
import { LOCATION, PAGE } from "../../../utils/constants/queryParamsKeys"
import { useRouter } from "next/router"
import RefreshIcon from "../../../assets/icons/RefreshIcon"
import { useTranslations } from "next-intl"
import { layoutLang } from "utils/language/layout"
import {
  deadlineItems,
  delayItems,
  filterTypes,
  repairedItems,
} from "./filterItems"
import FilterItem from "../filter-item/FilterItem"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { useGetClasses } from "pages/residential-complexes/services/queries"
import Lottie from "lottie-react"
import animationData from "../../../assets/lottie/Animation.json"
import { useGetNewPaymentMethods } from "pages/apartments/services/queries"


interface IProps {
  children?: JSX.Element
}

export const discounts = [
  {
    id: 1,
    text: {
      uz: "Chegirmali",
      ru: "Со скидкой",
    },
    name: "discount",
    value: "true",
  },
  {
    id: 2,
    text: {
      uz: "UYSOT bonus",
      ru: "UYSOT бонус",
    },
    name: "uysot_bonus",
    value: "true",
  },
]

const FilterDesktopModal = ({ children }: IProps) => {
  const t = useTranslations("body.Top")
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { data: classes } = useGetClasses()
  const { data: paymentMethods } = useGetNewPaymentMethods()
  const { data: region } = useGetRegionCity()
  const [loading, setLoading] = useState(false)
  const { push, query, locale, pathname } = useRouter()
  const { filter, filterType } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const { clearFilters, setChangeFilterType } = favouritesReducerActions
  const [visible, setVisible] = useState(false)
  const [regionId, setRegionId] = useState<number>()
  const [typeFilter, setTypeFilter] = useState(filterType)
  const [discount, setDiscount] = useState<
    { name: string; value: string } | undefined
  >(undefined)

  const { data: district } = useGetRegionDistricts(
    regionId || Number(query?.city_id),
    1
  )

  const viewType = pathname.includes("karta")

  const handleClose = () => {
    setLoading(false)
    setVisible(false)
  }

  const handleOpen = () => {
    setVisible(true)
  }

  const handlePriceFields = (field: string) => {
    const value = form.getFieldValue(field)
    form.setFieldValue(field, localeFormatter(value))
  }

  const onFinish = (values: any) => {
    setLoading(true)
    const updatedItems =
      discount !== undefined
        ? { ...filter, ...values, [discount?.name]: discount?.value }
        : { ...filter, ...values }

    let key: keyof typeof updatedItems

    for (key in updatedItems) {
      if (!updatedItems[key]) {
        delete updatedItems[key]
      }
    }

    const newQuery = viewType
      ? updatedItems
      : {
          ...updatedItems,
          [LOCATION]: query[LOCATION]?.toString()?.split("&")[0],
        }

    dispatch(setChangeFilterType(typeFilter))

    push({
      query: newQuery,
    }).then(handleClose)
  }

  useEffect(() => {
    form.setFieldsValue({
      ...query,
      delays: query?.delays
        ? Array.isArray(query?.delays)
          ? query?.delays?.map((item) => item)
          : [query?.delays]
        : null,
      class_ids: query?.class_ids
        ? Array.isArray(query?.class_ids)
          ? query?.class_ids?.map((item) => Number(item))
          : [Number(query?.class_ids)]
        : null,
      deadline_years: query?.deadline_years
        ? Array.isArray(query?.deadline_years)
          ? query?.deadline_years
          : [query?.deadline_years]
        : null,
      repaired: query?.repaired
        ? Array.isArray(query?.repaired)
          ? query?.repaired
          : [query?.repaired]
        : null,
      payment_method_ids: query?.payment_method_ids
        ? Array.isArray(query?.payment_method_ids)
          ? query?.payment_method_ids?.map((item) => Number(item))
          : [Number(query?.payment_method_ids)]
        : null,
      city_id: query?.city_id ? Number(query?.city_id) : null,
      district_id: query?.district_id ? Number(query?.district_id) : null,
      filter_type: query?.filter_type ? Number(query?.filter_type) : null,
    })

    if (query?.discount) {
      setDiscount({ name: "discount", value: String(query?.discount) })
    } else if (query?.uysot_bonus) {
      setDiscount({ name: "uysot_bonus", value: String(query?.uysot_bonus) })
    }
  }, [query, visible])

  const queryKeysLength = Object.keys(query)?.includes("view-type")
    ? Object.keys(query)?.length - 2
    : Object.keys(query)?.length

  const handleRefreshFilter = () => {
    const freshQuery: { page?: string; location: string } = {
      location: query[LOCATION]?.toString()?.split("&")[0] as string,
    }

    // check  page
    if (query[PAGE]) {
      freshQuery[PAGE] = query[PAGE] as string
    }

    push({
      query: viewType ? {} : freshQuery,
    })
    form.resetFields()
    setVisible(false)
    dispatch(clearFilters())
  }

  // const metros = [
  //   {
  //     id:1,
  //     name:"Chilonzor liniyasi",
  //     color:"#1E90FF"
  //   },
  //   {
  //     id:2,
  //     name:"O'zbekiston liniyasi",
  //     color:"#FF4405"
  //   },
  //   {
  //     id:3,
  //     name:"Yunusobod liniyasi",
  //     color:"#D444F1"
  //   },
  //   {
  //     id:4,
  //     name:"Qo'yliq liniyasi",
  //     color:"#9E77ED"
  //   }
  // ]
  //
  // const metroOptions = metros?.map((el,i)=>({
  //   value:el?.id,
  //   label:(
  //       <div className={cx("flex items-center gap-3", styles.metro_select_option, i === metros.length - 1 && styles.no_border)}>
  //         <MetroIcon fill={el?.color} />
  //         <span className="text-sm font-medium text-gray-700">{el?.name}</span>
  //       </div>
  //   ),
  //   props: {
  //     name: el?.name,
  //   },
  // }))

  return (
    <>
      {children && cloneElement(children, { onClick: handleOpen })}
      <Modal
        title={layoutLang[String(locale)]["Фильтр"]}
        footer={false}
        open={visible}
        onCancel={handleClose}
        width={1000}
        wrapClassName={styles.modal}
        centered
      >
        <Form
          className={styles.form}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className={styles.row}>
            {layoutLang[String(locale)]["Расположение"]}
            <Form.Item name="city_id">
              <Select
                size="large"
                placeholder={t("Область")}
                suffixIcon={<ArrowIcon />}
                onChange={(e) => {
                  setRegionId(e)
                  form.setFieldValue("district_id", null)
                }}
                allowClear
                onClear={() => {
                  form.setFieldValue("district_id", null)
                }}
              >
                {region?.map((item) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.name[locale as keyof typeof item.name]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="district_id">
              <Select
                size="large"
                placeholder={t("Район")}
                suffixIcon={<ArrowIcon />}
                allowClear
              >
                {district?.map((item) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.name[locale as keyof typeof item.name]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/*<Form.Item name="metro">*/}
            {/*  <Select*/}
            {/*    size="large"*/}
            {/*    mode="multiple"*/}
            {/*    allowClear*/}
            {/*    options={metroOptions}*/}
            {/*    placeholder={layoutLang[String(locale)]['Metro']}*/}
            {/*    suffixIcon={<ArrowIcon />}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
          </div>
          <div className={styles.row}>
            {t("Этаж")}
            <Form.Item name="min_floor">
              <Input
                onChange={() => handlePriceFields("min_floor")}
                size="large"
                placeholder={t("от")}
              />
            </Form.Item>
            <Form.Item name="max_floor">
              <Input
                onChange={() => handlePriceFields("max_floor")}
                size="large"
                placeholder={t("до")}
              />
            </Form.Item>
          </div>
          <div className={styles.row}>
            {t("Тип жилья")}
            <FilterItem data={repairedItems} name="repaired" />
          </div>
          {viewType && (
            <div className={styles.row}>
              {layoutLang[String(locale)]["korinish_turi"]}
              <div className="flex items-center gap-2">
                {filterTypes?.map((item) => (
                  <div
                    key={item?.id}
                    className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#0DC683] transition-all duration-300 ${
                      typeFilter === item?.value
                        ? "bg-[#e7fbf5]"
                        : "bg-[#f2f4f7]"
                    } ${
                      typeFilter === item?.value
                        ? "text-[#0DC683]"
                        : "text-[#000000D9]"
                    }`}
                    onClick={() =>
                      setTypeFilter(item?.value as "apartment" | "complex")
                    }
                  >
                    {item?.name[locale as keyof typeof item.name]}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.row}>
            {t("Площад от")}
            <Form.Item name="min_area">
              <Input
                onChange={() => handlePriceFields("min_area")}
                size="large"
                placeholder={t("от")}
                suffix="м²"
              />
            </Form.Item>
            <Form.Item name="max_area">
              <Input
                onChange={() => handlePriceFields("max_area")}
                size="large"
                placeholder={t("до")}
                suffix="м²"
              />
            </Form.Item>
          </div>

          <div className={styles.row}>
            {t("Условия оплаты")}
            <FilterItem data={delayItems} name="delays" />
          </div>
          <div className={styles.row}>
            {t("Класс жилья")}
            <FilterItem data={classes!} name="class_ids" />
          </div>
          <div className={styles.row}>
            {t("Дата окончания")}
            <FilterItem data={deadlineItems} name="deadline_years" />
          </div>
          <div className={styles.row}>
            {layoutLang[String(locale)]["tolov_turi"]}
            <FilterItem data={paymentMethods!} name="payment_method_ids" />
          </div>
          <div className={styles.row}>
            {layoutLang[String(locale)]["Chegirma va bonuslar"]}
            <div className="flex items-center gap-2">
              {discounts?.map((item) => (
                <div
                  key={item?.id}
                  className={`relative h-[37px] px-4 rounded-[4px] flex items-center justify-center text-base font-medium cursor-pointer hover:text-[#0DC683] transition-all duration-300 ${
                    item?.name === discount?.name
                      ? "text-[#0DC683] bg-[#e7fbf5]"
                      : "bg-[#F2F4F7]"
                  } ${item?.name === "uysot_bonus" ? "pr-14" : ""}`}
                  onClick={() => {
                    if (item?.name === discount?.name) {
                      setDiscount(undefined)
                    } else {
                      setDiscount({ name: item?.name, value: item?.value })
                    }
                  }}
                >
                  <span>{item?.text[locale as keyof typeof item.text]}</span>
                  {item?.name === "uysot_bonus" && (
                    <div className="w-14 h-14 absolute right-0">
                      <Lottie animationData={animationData} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.footer_clear} onClick={handleRefreshFilter}>
              <RefreshIcon />
              <span>
                {t("Сбросить")} ({queryKeysLength})
              </span>
            </div>
            <Button
              htmlType="submit"
              size="large"
              className="rounded-lg"
              type="primary"
              loading={loading}
            >
              {t("Сохранить")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default FilterDesktopModal
