import queryString from "query-string"

export const endpoints = {
  CALL_BACK: "/lead/create",
  PAYMENT_METHODS_SELECT: "/general/payment/method/select",
  API_BANNER_VIEW: (query?: {
    page_type?: string
    uuid?: string
    lang?: string
  }) => `/api/banner/view?${queryString.stringify(query || {})}`,
  API_BANNER_VIEW_UUID: (uuid?: string) => `/api/banner/view/${uuid}`,
}
