import React, { ReactNode } from "react"
import Header from "./components/Header"
import Footer from "./components/Footer"
import styles from "./expo.module.scss"

type Props = {
  children: ReactNode
}

const Expo = ({ children }: Props) => {
  return (
    <>
      <div className={styles.container}>
        <Header />
        {children}
        <Footer />
      </div>
    </>
  )
}

export default Expo
