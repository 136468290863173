import ReactInputMask from "react-input-mask"

type Props = {
  mask: string
  placeholder?: string
  type?: string
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  borderStatus?: boolean
}

export const InputMaskCopy: React.FC<Props> = ({
  mask,
  placeholder,
  type,
  onChange,
  value,
  borderStatus,
}) => {
  return (
    <ReactInputMask
      mask={mask}
      // @ts-ignore
      maskChar=""
      className={
        type === "quiz"
          ? `h-11 rounded-xl bg-[#F2F4F7] ${
              borderStatus ? "!border-red-500" : "border-none"
            } ant-input`
          : "ant-input"
      }
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  )
}
