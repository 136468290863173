import Image from "next/image"
import Link from "next/link"

import AppStore from "../../assets/images/app-store.png"
import Google from "../../assets/images/google-app.png"
import PhonePng from "../../assets/images/phone.png"
import qr from "../../assets/images/qr.png"

import styles from "./MobileAppDownload.module.scss"
import { useRouter } from "next/router"
import { layoutLang } from "../../../../utils/language/layout"

const MobileAppDownload = () => {
  const { locale } = useRouter()
  return (
    <section className={styles.app_download}>
      <div className="wrapper">
        <div className={styles.app_download__body}>
          <div className={styles.app_links}>
            <h3>
              {
                layoutLang[String(locale)][
                  "Загрузите сейчас! Не пропустите акции и скидки"
                ]
              }
            </h3>
            <div>
              <Link
                href="https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_link&itscg=30200"
                legacyBehavior
              >
                <a target="_blank" rel="noopener">
                  <Image src={AppStore} alt="app-store-img" />
                </a>
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                legacyBehavior
              >
                <a target="_blank" rel="noopener">
                  <Image src={Google} alt="google-play-img" />
                </a>
              </Link>
            </div>
          </div>
          <div className={styles.app_phone}>
            <Image src={PhonePng} alt="phone-img" />
          </div>
          <div className={styles.qr_link}>
            <Image src={qr} width={100} height={100} alt="qr-code-img" />
            <p>
              {
                layoutLang[String(locale)][
                  "Наведите камеру на QR-код, чтобы скачать"
                ]
              }
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileAppDownload
