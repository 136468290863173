import React from "react"

const UzFlagIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9609_521)">
        <rect width="19" height="16" rx="3" fill="#F93939" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.7334H19V16.0001H0V11.7334Z"
          fill="#00B731"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H19V4.26667H0V0Z"
          fill="#03BCDB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.33332H19V10.6667H0V5.33332ZM4.52381 4.17598C4.38267 4.23465 4.22976 4.26665 4.07143 4.26665C3.32229 4.26665 2.71429 3.54985 2.71429 2.66665C2.71429 1.78345 3.32229 1.06665 4.07143 1.06665C4.22976 1.06665 4.38267 1.09865 4.52381 1.15732C3.99633 1.37705 3.61905 1.97012 3.61905 2.66665C3.61905 3.36318 3.99633 3.95732 4.52381 4.17598Z"
          fill="white"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.42859 2.13332H6.33335V3.19998H5.42859V2.13332ZM6.33335 1.06665H7.23811V2.13332H6.33335V1.06665ZM7.23811 2.13332H8.14287V3.19998H7.23811V2.13332ZM8.14287 1.06665H9.04764V2.13332H8.14287V1.06665ZM9.04764 2.13332H9.9524V3.19998H9.04764V2.13332ZM8.14287 3.19998H9.04764V4.26665H8.14287V3.19998ZM6.33335 3.19998H7.23811V4.26665H6.33335V3.19998Z"
          fill="#1A47B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_9609_521">
          <rect width="19" height="16" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UzFlagIcon
