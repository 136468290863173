import React from "react"

const FacebookMiniIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1793 31.6454V20.9662H8.86852V16.0589H12.1793V13.9445C12.1793 8.47775 14.6524 5.94446 20.0187 5.94446C20.5554 5.94446 21.2968 6.00067 21.9833 6.0823C22.4975 6.13522 23.0076 6.22239 23.5102 6.34326V10.7929C23.2196 10.7658 22.9281 10.7497 22.6363 10.7447C22.3094 10.7362 21.9824 10.7322 21.6554 10.7327C20.7093 10.7327 19.9706 10.8612 19.4139 11.1462C19.0396 11.334 18.725 11.6222 18.5052 11.9786C18.1599 12.5406 18.0047 13.3101 18.0047 14.3232V16.0589H23.2492L22.7327 18.8732L22.3486 20.9662H18.0047V32C25.9565 31.0391 32.1178 24.269 32.1178 16.0589C32.1178 7.19037 24.9274 0 16.0589 0C7.19037 0 0 7.19037 0 16.0589C0 23.5905 5.18434 29.9097 12.1793 31.6454Z"
        fill="white"
        fillOpacity="0.55"
      />
    </svg>
  )
}

export default FacebookMiniIcon
