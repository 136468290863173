export const minPrice = [
  200000000, 300000000, 400000000, 500000000, 600000000, 700000000,
]
export const maxPrice = [
  300000000, 400000000, 500000000, 600000000, 700000000, 800000000,
]
export const minPerPrice = [
  2000000, 2500000, 3000000, 3500000, 4000000, 4500000,
]
export const maxPerPrice = [
  3000000, 3500000, 4000000, 4500000, 5000000, 8000000, 12000000, 15000000,
]
export const minInitPrice = [
  100000000, 120000000, 160000000, 200000000, 240000000, 280000000,
]
export const maxInitPrice = [
  120000000, 160000000, 200000000, 240000000, 280000000, 360000000,
]
export const hotSearchs = [
  {
    link: "/gorod-tashkent/kvartiri?min_area=23&max_area=70&sort_value=asc&sort_key=area",
    value: { uz: "23-70 m²", ru: "23-70 м²" },
  },
  {
    link: "/gorod-tashkent/kvartiri?payment_method_ids=1",
    value: {
      uz: "Ipoteka",
      ru: "Ипотека",
    },
  },
  {
    link: "/gorod-tashkent/kvartiri?city_id=12",
    value: { uz: "Toshkent markazidan", ru: "из центра Ташкента" },
  },
  {
    link: "/gorod-tashkent/kvartiri?class_ids=2",
    value: { uz: "Biznes klass", ru: "Бизнес класс" },
  },
  {
    link: "/gorod-tashkent/kvartiri?completed_complex=true",
    value: { uz: "Tayyor uylar 2024", ru: "Сданные новостройки 2024" },
  },
]
export const initialRooms = [
  {
    label: "Студия",
    key: "0",
    isTop: false,
  },
  {
    label: "1",
    key: "1",
    isTop: true,
  },
  {
    label: "2",
    key: "2",
    isTop: false,
  },
  {
    label: "3",
    key: "3",
    isTop: false,
  },
  {
    label: "4+",
    key: "4",
    isTop: false,
  },
]
