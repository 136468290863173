import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../../store/reducers/userReducer"
import { rootPaths } from "../../../../../utils/constants/rootPaths"
import { menuLinks } from "../../../utils/constants/menuLinks"
import { layoutLang } from "../../../../../utils/language/layout"

import Link from "next/link"
import FavouriteIcon from "../../../../../assets/icons/FavouriteIcon"
import UysotIcon from "../../../../../assets/icons/UysotIcon"
import HamburgerIcon from "../../../assets/icon/HamburgerIcon"

import styles from "./HeaderBottom.module.scss"
import FilterDesktop from "common/filter-desktop/FilterDesktop"
import { Grid } from "antd"
import UzFlagIcon from "assets/icons/UzFlagIcon"
import React from "react"
import RuFlagIcon from "assets/icons/RuFlagIcon"
import HeaderTop from "../top/HeaderTop"

const HeaderBottom: React.FC = () => {
  const dispatch = useAppDispatch()
  const { push, pathname, locale } = useRouter()
  const { toggleVisibleMobileMenu, setChangeMenu, setOpenLang } =
    userReducerActions
  const { menuIcon } = useAppSelector((state) => state.userReducer)
  const { lang } = useAppSelector((state) => state.localeReducer)
  const { location: currentLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const { md } = Grid.useBreakpoint()

  const region_id = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city?.id
      ? currentLocation?.regionValue?.city?.id
      : currentLocation?.regionValue?.district?.city?.id
    : undefined

  const district_id =
    currentLocation?.regionValue && currentLocation?.regionValue?.district
      ? currentLocation?.regionValue?.district?.id
      : undefined

  const location = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url
    : "gorod-tashkent"

  // back to main
  // const backToMain = () => {
  //   push(rootPaths.INDEX)
  // }

  // handle open mobile menu
  const handleOpenMobilMenu = () => {
    dispatch(toggleVisibleMobileMenu())
    dispatch(setChangeMenu(!menuIcon))
  }

  const handleOpenLang = () => {
    dispatch(setOpenLang(true))
  }

  return (
    <div className={`${styles.header_bottom} flex flex-col w-full`}>
      <div className="wrapper max-w-[1320px] mx-auto relative z-20">
        <div className={`${styles.header_bottom_content} justify-between`}>
          <div className="flex items-center gap-7 h-full">
            <div className={styles.header_bottom_content_left}>
              <div className="w-[42px] h-[42px] border border-solid border-[#EAECF0] rounded-xl md:hidden flex items-center justify-center">
                <HamburgerIcon
                  className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`}
                  onClickFunc={handleOpenMobilMenu}
                />
              </div>
              <Link
                href={rootPaths.INDEX}
              >
                <UysotIcon />
              </Link>
            </div>
            <nav className="desktopD" aria-hidden suppressHydrationWarning>
              <ul className={styles.header_bottom_content_center}>
                {menuLinks(location, region_id, district_id).map(
                  (item, index) => (
                    <li key={index}>
                      <Link
                        href={item.path}
                        className={
                          pathname.includes(item?.basePath) ? styles.active : ""
                        }
                      >
                        {
                          layoutLang[String(locale) as any]![
                            String(item?.label)
                          ]
                        }
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </div>
          <div className="desctop_only">
            <HeaderTop />
          </div>
          <div
            className={`${styles.header_bottom_content_right} mobileD flex items-center gap-3`}
            aria-hidden
            suppressHydrationWarning
          >
            <Link href={rootPaths.FAVOURITES} legacyBehavior>
              <a className="border border-solid border-[#EAECF0] h-[42px] w-[42px] flex items-center justify-center rounded-xl">
                <FavouriteIcon width={16} height={16} />
              </a>
            </Link>
            <div
              className="flex items-center h-[42px] gap-2.5 p-2.5 border border-solid border-[#EAECF0] rounded-xl cursor-pointer"
              onClick={handleOpenLang}
            >
              {lang === "uz" ? (
                <>
                  <UzFlagIcon />
                  <span className="text-[#475467] font-semibold">O‘z</span>
                </>
              ) : (
                <>
                  <RuFlagIcon />
                  <span className="text-[#475467] font-semibold">РУ</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {md && <div className="border-b"></div>}
      <div className="desctop_only">
        {(pathname === "/[location]/novostroyki" ||
          pathname === "/[location]/kvartiri" ||
          pathname === "/karta") && <FilterDesktop />}
      </div>
    </div>
  )
}

export default HeaderBottom
