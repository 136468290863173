import { $newapi } from "services/RequestService"
import { AllRegionsModel } from "../utils/models/AllRegionsModel"
import { ErrorRes } from "utils/models/responseType"
import { useQuery } from "@tanstack/react-query"
import { errorHandler } from "utils/helpers/errorHandler"
import { queryKeys } from "../utils/constants/queryKeys"

export function useGetAllRegion(search: string) {
  return useQuery<AllRegionsModel[], ErrorRes>({
    queryKey: [queryKeys.ALL_REGIONS, search],
    queryFn: async () => {
      const url =
        search?.length > 0
          ? `/district/filter?search=${search}`
          : `/district/filter?default=true`
      const res = await $newapi.get(url)

      return res.data.data
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    // staleTime: Infinity,
    // enabled: !!search,
  })
}
