import React from "react"

const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.66667H11.8333C9.16667 1.66667 7.5 3.33334 7.5 6V9.375H12.7083C13.05 9.375 13.3333 9.65834 13.3333 10C13.3333 10.3417 13.05 10.625 12.7083 10.625H7.5V14C7.5 16.6667 9.16667 18.3333 11.8333 18.3333H13.9917C16.6583 18.3333 18.325 16.6667 18.325 14V6C18.3333 3.33334 16.6667 1.66667 14 1.66667Z"
        fill="#7C7C7C"
      />
      <path
        d="M3.80001 9.375L5.52501 7.65C5.65001 7.525 5.70835 7.36667 5.70835 7.20834C5.70835 7.05 5.65001 6.88334 5.52501 6.76667C5.28335 6.525 4.88335 6.525 4.64168 6.76667L1.85001 9.55834C1.60835 9.8 1.60835 10.2 1.85001 10.4417L4.64168 13.2333C4.88335 13.475 5.28335 13.475 5.52501 13.2333C5.76668 12.9917 5.76668 12.5917 5.52501 12.35L3.80001 10.625H7.50001V9.375H3.80001V9.375Z"
        fill="#7C7C7C"
      />
    </svg>
  )
}

export default LogoutIcon
