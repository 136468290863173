export const endpoints = {
  REGISTER: "/auth/register",
  LOGIN: "/auth/login",
  LOCATION: "/location",
  ADDRESS: "/address",
  AUTH: "/auth/check",
  LOGOUT: "/auth/logout",
  FAVOURITES: "/favorite/view",
  REFRESH: "/refresh",
  REGION_DISTRICTS: "/district/select",
  CITY: "/city/select",
}
