import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"

import { errorHandler } from "utils/helpers/errorHandler"
import { NewApartmentCardModel } from "utils/models/apartmentModel"
import { Pagination } from "utils/models/pagination"
import { ErrorRes } from "utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { PaymentMethodsAndClassesType } from "../utils/models/models"
import { fetchApartments, fetchNewPaymentMethods } from "./queryFunctions"
import { $newapi } from "services/RequestService"
import { AllRegionsModel } from "pages/main-layout/components/region-city/utils/models/AllRegionsModel"

// get apartments
export function useGetApartments(id?: number) {
  const { query: getQuery } = useRouter()
  const location = String(getQuery?.location).split("&")[0]
  const query = {
    ...getQuery,
    location,
  }

  const { url, fetch } = fetchApartments({
    query: getQuery,
    complex_id: id as number,
  })

  return useQuery<Pagination<NewApartmentCardModel>, ErrorRes>({
    queryKey: [queryKeys.APARMENTS, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get classes for select
export function useGetNewPaymentMethods() {
  return useQuery<PaymentMethodsAndClassesType[], ErrorRes>({
    queryKey: [queryKeys.PAYMENT_METHODS],
    queryFn: fetchNewPaymentMethods,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

export function useGetAllRegion(search: string) {
  return useQuery<AllRegionsModel[], ErrorRes>({
    queryKey: [queryKeys.ALL_REGIONS, search],
    queryFn: async () => {
      const url =
        search?.length > 0
          ? `/district/filter?search=${search}`
          : `/district/filter?default=true`
      const res = await $newapi.get(url)

      return res.data.data
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
    // enabled: !!search,
  })
}
