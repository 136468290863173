import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { localeReducerActions } from "store/reducers/localeReducer"
import { userReducerActions } from "store/reducers/userReducer"
import { USD, UZS } from "utils/constants/locales"
import { layoutLang } from "utils/language/layout"

const MenuCurrency = () => {
  const { locale } = useRouter()
  const dispatch = useAppDispatch()
  const { currency } = useAppSelector((state) => state.localeReducer)
  const { openCurrency } = useAppSelector((state) => state.userReducer)
  const { changeUsdValue, changeCurrency } = localeReducerActions
  const { setOpenCurrency } = userReducerActions
  const handleClose = () => {
    dispatch(setOpenCurrency(false))
  }

  useEffect(() => {
    fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`)
      .then((res) => res.json())
      .then((res: any[]) => {
        dispatch(changeUsdValue(res?.find((item) => item?.Ccy === "USD")?.Rate))
      })
  }, [])

  return (
    <div
      className={`${
        openCurrency ? "bg-[#00000040]" : "bg-transparent"
      } fixed w-full h-full left-0 bottom-0 ${
        openCurrency ? "z-[10000]" : "-z-10"
      } transition-all duration-500`}
    >
      <div
        className="w-full h-full absolute top-0 left-0 z-10"
        onClick={handleClose}
      />
      <div
        className={`bg-white w-full fixed rounded-t-2xl z-20 ${
          openCurrency ? "bottom-0" : "bottom-[-100%]"
        } transition-all duration-500`}
      >
        <div
          className={`absolute z-2 bg-white rounded-t-2xl flex flex-col gap-3 w-full left-0 py-7 px-4 ${
            openCurrency ? "bottom-0" : "bottom-[-100%]"
          } transition-all duration-500`}
        >
          <span className="text-[#101828] text-xl font-medium">
            {layoutLang[String(locale)]["Valyuta"]}
          </span>
          <div className="flex items-center gap-3">
            <div
              className={`flex-1 h-11 flex items-center justify-center text-[#1D2939] text-base font-medium cursor-pointer rounded-xl border border-solid hover:text-[#2BCD93] hover:border-[#2BCD93] hover:bg-[#E9FAF4] transition-all duration-500 ${
                currency === "usd" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"
              } ${
                currency === "usd" ? "border-[#2BCD93]" : "border-transparent"
              } ${currency === "usd" ? "text-[#2BCD93]" : "text-[#1D2939]"}`}
              onClick={() => {
                dispatch(changeCurrency(USD))
                dispatch(setOpenCurrency(false))
              }}
            >
              USD
            </div>
            <div
              className={`flex-1 h-11 flex items-center justify-center text-[#1D2939] text-base font-medium cursor-pointer rounded-xl border border-solid hover:text-[#2BCD93] hover:border-[#2BCD93] hover:bg-[#E9FAF4] transition-all duration-500 ${
                currency === "uzs" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"
              } ${
                currency === "uzs" ? "border-[#2BCD93]" : "border-transparent"
              } ${currency === "uzs" ? "text-[#2BCD93]" : "text-[#1D2939]"}`}
              onClick={() => {
                dispatch(changeCurrency(UZS))
                dispatch(setOpenCurrency(false))
              }}
            >
              UZS
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuCurrency
