import React from "react"
type Icon = {
  className?: string
  onClick?: () => void
  size?: string
  color?: string
}
const FirstFilterIcon: React.FC<Icon> = ({
  className,
  onClick,
  size,
  color,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FirstFilterIcon
