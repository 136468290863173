import React, { useEffect, useState } from "react"

import styles from "./filterPriceModal.module.scss"
import ArrowIcon from "../../../assets/icons/ArrowIcon"
import { Col, Dropdown, Input, Row, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"
import { localeFormatter } from "utils/helpers/localeFormatter"

const FilterPriceModal = () => {
  const t = useTranslations("body.Top")
  const dispatch = useAppDispatch()
  const { query, pathname } = useRouter()
  const { filter } = useAppSelector((state) => state.favouritesReducer)
  const [activeTab, setActiveTab] = useState("1")

  const {
    setMaxTotalPrice,
    setMinTotalPrice,
    setMinAreaPrice,
    setMaxAreaPrice,
    setMinPrePayment,
    setMaxPrePayment,
    clearFilters,
  } = favouritesReducerActions

  useEffect(() => {
    dispatch(clearFilters())
  }, [pathname])

  const setQueryParams = {
    min_total_price: setMinTotalPrice,
    max_total_price: setMaxTotalPrice,
    prepayment_min: setMinPrePayment,
    prepayment_max: setMaxPrePayment,
    price_min_per_area: setMinAreaPrice,
    price_max_per_area: setMaxAreaPrice,
  }

  useEffect(() => {
    Object.entries(query).forEach(([key, value]) => {
      const action = setQueryParams[key as keyof typeof setQueryParams]
      if (action && value) {
        dispatch(action(Number(value)))
      }
    })
  }, [query])

  const tabItems = [
    {
      key: "1",
      label: t("Цена"),
      children: (
        <Row gutter={6}>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMinTotalPrice(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.min_total_price
                  ? localeFormatter(String(filter?.min_total_price))
                  : ""
              }
              placeholder={t("от")}
              suffix="UZS"
            />
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMaxTotalPrice(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.max_total_price
                  ? localeFormatter(String(filter?.max_total_price))
                  : ""
              }
              placeholder={t("до")}
              suffix="UZS"
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: <span>{t(`Цена`) + `(${t("м2")})`}</span>,
      children: (
        <Row gutter={6}>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMinAreaPrice(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.price_min_per_area
                  ? localeFormatter(String(filter?.price_min_per_area))
                  : ""
              }
              placeholder={t("от")}
              suffix="UZS"
            />
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMaxAreaPrice(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.price_max_per_area
                  ? localeFormatter(String(filter?.price_max_per_area))
                  : ""
              }
              placeholder={t("до")}
              suffix="UZS"
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: t("Первоначальный платеж"),
      children: (
        <Row gutter={6}>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMinPrePayment(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.prepayment_min
                  ? localeFormatter(String(filter?.prepayment_min))
                  : ""
              }
              placeholder={t("от")}
              suffix="UZS"
            />
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) => {
                dispatch(
                  setMaxPrePayment(Number(e.target.value?.replace(/\s/g, "")))
                )
              }}
              value={
                filter?.prepayment_max
                  ? localeFormatter(String(filter?.prepayment_max))
                  : ""
              }
              placeholder={t("до")}
              suffix="UZS"
            />
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Dropdown
      overlay={
        <div className={styles.dropdownOverlay}>
          <Tabs
            activeKey={activeTab}
            items={tabItems}
            className="pb-3"
            onChange={(e) => setActiveTab(e)}
          />
        </div>
      }
      onOpenChange={() => setActiveTab("1")}
      trigger={["click", "hover"]}
      mouseEnterDelay={1000}
      mouseLeaveDelay={0.3}
    >
      <div className={styles.dropDownBtn}>
        <span className="placeholder">
          {filter?.min_total_price ||
          filter?.prepayment_min ||
          filter?.price_min_per_area ||
          filter?.max_total_price ||
          filter?.prepayment_max ||
          filter?.price_max_per_area
            ? `${Number(
                filter?.min_total_price ||
                  filter?.prepayment_min ||
                  filter?.price_min_per_area
              )?.toLocaleString("ru")} UZS - ${Number(
                filter?.max_total_price ||
                  filter?.prepayment_max ||
                  filter?.price_max_per_area
              ).toLocaleString("ru")} UZS`
            : t(`Цена`)}
        </span>
        <ArrowIcon />
      </div>
    </Dropdown>
  )
}

export default FilterPriceModal
