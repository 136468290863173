import ReactInputMask from "react-input-mask"

type Props = {
  mask: string
  placeholder?: string
  type?: string
}

export const InputMask: React.FC<Props> = ({ mask, placeholder, type }) => {
  return (
    <ReactInputMask
      mask={mask}
      // @ts-ignore
      maskChar=""
      className={
        type === "quiz"
          ? "h-11 rounded-xl bg-[#F2F4F7] border-none ant-input"
          : "ant-input"
      }
      placeholder={placeholder}
    />
  )
}
