import React from "react"

const TelegramMiniIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM23.424 10.88C23.184 13.408 22.144 19.552 21.616 22.384C21.392 23.584 20.944 23.984 20.528 24.032C19.6 24.112 18.896 23.424 18 22.832C16.592 21.904 15.792 21.328 14.432 20.432C12.848 19.392 13.872 18.816 14.784 17.888C15.024 17.648 19.12 13.92 19.2 13.584C19.2111 13.5331 19.2096 13.4803 19.1957 13.4301C19.1818 13.3799 19.1558 13.3339 19.12 13.296C19.024 13.216 18.896 13.248 18.784 13.264C18.64 13.296 16.4 14.784 12.032 17.728C11.392 18.16 10.816 18.384 10.304 18.368C9.728 18.352 8.64 18.048 7.824 17.776C6.816 17.456 6.032 17.28 6.096 16.72C6.128 16.432 6.528 16.144 7.28 15.84C11.952 13.808 15.056 12.464 16.608 11.824C21.056 9.968 21.968 9.648 22.576 9.648C22.704 9.648 23.008 9.68 23.2 9.84C23.36 9.968 23.408 10.144 23.424 10.272C23.408 10.368 23.44 10.656 23.424 10.88Z"
        fill="white"
        fillOpacity="0.55"
      />
    </svg>
  )
}

export default TelegramMiniIcon
