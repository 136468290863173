import { Input, Spin } from "antd"
import CloseIcon from "assets/icons/CloseIcon"
import SearchIcon from "assets/icons/SearchIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { layoutLang } from "utils/language/layout"
import { useGetAllRegion } from "./services/queries"
import MenuLocationIcon from "assets/icons/MenuLocationIcon"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import { isBrowser } from "utils/helpers/isBrowser"

const RegionSelect = () => {
  const dispatch = useAppDispatch()
  const { location: commonLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const { locale } = useRouter()
  const [time, setTime] = useState<any>()
  const [val, setVal] = useState("")
  const { data, isLoading } = useGetAllRegion(commonLocation?.searchValue)

  const {
    setRegionId,
    setDistrictId,
    setOpenRegionSelect,
    setSearchValue,
    setCurrentRegion,
  } = favouritesReducerActions
  const { location } = useAppSelector((state) => state.favouritesReducer)

  useEffect(() => {
    if (location.openRegionSelect) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.overflowX = "hidden"
    }
  }, [location.openRegionSelect])

  const handleChangeSearch = (value: string) => {
    setVal(value)
    clearTimeout(time)
    if (value) {
      setTime(
        setTimeout(() => {
          dispatch(setSearchValue(value))
        }, 800)
      )
    }
  }

  return (
    <div
      className={`fixed flex flex-col gap-3  left-0 w-full h-full bg-[#F2F4F7] z-[1003] ${
        location?.openRegionSelect ? "bottom-0" : "bottom-[-100%]"
      } transition-all duration-500`}
    >
      <div className="flex items-center justify-between bg-white p-3">
        <span className="text-[#1D2939] text-lg font-medium">
          {layoutLang[String(locale)]["Qidirish"]}
        </span>
        <CloseIcon onClick={() => dispatch(setOpenRegionSelect(false))} />
      </div>
      <div className="p-2.5 bg-white rounded-xl">
        <Input
          prefix={<SearchIcon color="#98A2B3" size="18" />}
          placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`}
          value={val}
          onChange={(e) => {
            handleChangeSearch(e?.target?.value)
          }}
          className="h-[43px] rounded-xl border-[#EAECF0]"
        />
      </div>

      <div
        className="flex flex-col gap-3 p-3 rounded-xl bg-white mobileTopFilter"
        style={{ height: "calc(100vh - 130px)", overflow: "auto" }}
      >
        <Spin spinning={isLoading}>
          <div className="flex flex-col gap-3">
            <div
              className={`flex items-center gap-3 px-1 pb-3 border-b border-solid border-[#EAECF0] cursor-pointer`}
              onClick={() => {
                setVal("")
                dispatch(setRegionId(null))
                dispatch(setDistrictId(null))
                dispatch(setOpenRegionSelect(false))
                dispatch(setCurrentRegion(undefined))
                localStorage.setItem("currentRegion", JSON.stringify(undefined))
              }}
            >
              <MenuLocationIcon />
              <div className="flex flex-col">
                <span className="text-[#344054] font-medium">
                  {layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
                </span>
              </div>
            </div>
            {data?.map((item, index) => (
              <div key={index}>
                {item?.district ? (
                  <div
                    className={`flex items-center gap-3 px-1 pb-3 ${
                      index === data?.length - 1 ? "border-none" : "border-b"
                    } border-solid border-[#EAECF0] cursor-pointer`}
                    onClick={() => {
                      setVal("")
                      dispatch(setRegionId(item?.district?.city?.id))
                      dispatch(setDistrictId(item?.district?.id))
                      dispatch(setOpenRegionSelect(false))
                      dispatch(setCurrentRegion(item))
                      localStorage.setItem(
                        "currentRegion",
                        JSON.stringify(item)
                      )
                    }}
                  >
                    <MenuLocationIcon />
                    <div className="flex flex-col">
                      <span className="text-[#344054] font-medium">
                        {
                          item?.district?.name?.[
                            locale as keyof typeof item.district.name
                          ]
                        }
                      </span>
                      <span className="text-[#667085] text-xs">
                        {
                          item?.district?.city?.name[
                            locale as keyof typeof item.district.city.name
                          ]
                        }
                      </span>
                    </div>
                    {location.regionValue?.district?.id ===
                      item?.district?.id && (
                      <div className="absolute right-5">
                        <RegCheckIcon />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`flex items-center gap-3 px-1 pb-3 ${
                      index === data?.length - 1 ? "border-none" : "border-b"
                    } border-solid border-[#EAECF0] cursor-pointer`}
                    onClick={() => {
                      setVal("")
                      dispatch(setRegionId(item?.city?.id))
                      dispatch(setDistrictId(null))
                      dispatch(setOpenRegionSelect(false))
                      dispatch(setCurrentRegion(item))
                      localStorage.setItem(
                        "currentRegion",
                        JSON.stringify(item)
                      )
                    }}
                  >
                    <MenuLocationIcon />
                    <span>
                      {item?.city?.name[locale as keyof typeof item.city.name]}
                    </span>
                    {location?.regionValue?.city?.id === item?.city?.id && (
                      <div className="absolute right-5">
                        <RegCheckIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default RegionSelect
