import React from "react"

type Props = {
  onClickFunc?: () => void
  className?: string
}

const HamburgerIcon: React.FC<Props> = ({ onClickFunc, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickFunc}
      className={className && className}
    >
      <path
        d="M4 6H20"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 12H20"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 18H20"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default HamburgerIcon
