import RuFlagIcon from "assets/icons/RuFlagIcon"
import UzFlagIcon from "assets/icons/UzFlagIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import React from "react"
import { localeReducerActions } from "store/reducers/localeReducer"
import { userReducerActions } from "store/reducers/userReducer"
import { layoutLang } from "utils/language/layout"

const LangDropdown = () => {
  const dispatch = useAppDispatch()
  const { setOpenLang } = userReducerActions
  const { changeLanguage } = localeReducerActions
  const { openLang } = useAppSelector((state) => state.userReducer)
  const { lang } = useAppSelector((state) => state.localeReducer)
  const router = useRouter()
  const handleClose = () => {
    dispatch(setOpenLang(false))
  }

  const changeLang = (language: "uz" | "ru") => {
    dispatch(changeLanguage(language))
    router
      .push(router.asPath, router.asPath, { locale: language })
      .then(handleClose)
  }

  return (
    <div
      className={`${
        openLang ? "bg-[#00000040]" : "bg-transparent"
      } fixed w-full h-full left-0 bottom-0 ${
        openLang ? "z-[10000]" : "-z-10"
      } transition-all duration-500`}
    >
      <div
        className="w-full h-full absolute top-0 left-0 z-10"
        onClick={handleClose}
      />
      <div
        className={`bg-white w-full fixed rounded-t-2xl z-20 ${
          openLang ? "bottom-0" : "bottom-[-100%]"
        } transition-all duration-500`}
      >
        <div className="py-7 px-4 flex flex-col gap-6">
          <span className="text-[#101828] text-xl font-medium">
            {layoutLang[String(router.locale)]["Til"]}
          </span>
          <div className="flex items-center justify-between gap-3 w-full">
            <div
              className={`flex items-center gap-3 w-full  h-11 rounded-xl justify-center cursor-pointer border border-solid hover:border-[#0DC683] hover:text-[#0DC683] hover:bg-[#E9FAF4] transition-all duration-300 ${
                lang === "uz" ? "border-[#0DC683]" : "border-transparent"
              } ${lang === "uz" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"}`}
              onClick={() => changeLang("uz")}
            >
              <UzFlagIcon />
              <span
                className={`text-base font-medium ${
                  lang === "uz" ? "text-[#0DC683]" : "text-[#1D2939]"
                }`}
              >
                O‘zbekcha
              </span>
            </div>
            <div
              className={`flex items-center gap-3 w-full  h-11 rounded-xl justify-center cursor-pointer border border-solid hover:border-[#0DC683] hover:text-[#0DC683] hover:bg-[#E9FAF4] transition-all duration-300 ${
                lang === "ru" ? "border-[#0DC683]" : "border-transparent"
              } ${lang === "ru" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"}`}
              onClick={() => changeLang("ru")}
            >
              <RuFlagIcon />
              <span
                className={`text-base font-medium ${
                  lang === "ru" ? "text-[#0DC683]" : "text-[#1D2939]"
                }`}
              >
                Русский
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LangDropdown
