import React from "react"

const MetroIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="14" height="13" rx="2" fill="#2F75FF" />
      <path
        d="M3 10.5V3.5H3.01055L7.32718 8.80026L6.67282 8.67232L10.9789 3.5H11V10.5H9.46966V6.48825L9.56464 7.17363L6.93668 10.4086H6.91557L4.21372 7.17363L4.47757 6.54308V10.5H3Z"
        fill="white"
      />
    </svg>
  )
}

export default MetroIcon
