import { Button } from "antd"
import CloseIcon from "assets/icons/CloseIcon"
import NotifIcon from "assets/icons/NotifIcon"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import { useRouter } from "next/router"
import { sendRequest } from "pages/firebase/firebase"
import React, { useEffect, useState } from "react"
import { isBrowser } from "utils/helpers/isBrowser"
import { layoutLang } from "utils/language/layout"

const Notification = () => {
  const { locale } = useRouter()
  const [deviceToken, setDeviceToken] = useState(
    isBrowser() ? localStorage.getItem("deviceToken") : ""
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasAccepted, setHasAccepted] = useState(false)

  useEffect(() => {
    const modalAccepted = localStorage.getItem("modalAccepted")
    const modalClosedAt = localStorage.getItem("modalClosedAt")
    const currentTime = new Date().getTime()

    if (modalAccepted) {
      setHasAccepted(true)
      return
    }

    if (modalClosedAt) {
      const oneHour = 60 * 60 * 1000
      if (currentTime - parseInt(modalClosedAt, 10) < oneHour) {
        return
      }
    }

    const timer = setTimeout(() => {
      if (!hasAccepted && !deviceToken) {
        setIsModalOpen(true)
      }
    }, 60000)

    return () => clearTimeout(timer)
  }, [hasAccepted])

  const handleClose = () => {
    setIsModalOpen(false)
    localStorage.setItem("modalClosedAt", JSON.stringify(new Date().getTime()))
  }

  const handleAccept = () => {
    setIsModalOpen(false)
    setHasAccepted(true)
    localStorage.setItem("modalAccepted", "true")
    sendRequest(setDeviceToken)
  }

  return (
    <NonSSRWrapper>
      <div
        className="fixed z-[1000] w-full h-screen bg-[#00000040] left-0 bottom-0 transition-all duration-700"
        style={{
          left: !isModalOpen ? "-120%" : "0",
        }}
      >
        <div
          className={`bg-white shadow-md rounded-3xl fixed left-[50%]  items-center flex flex-col gap-2 z-50 transition-all duration-700 sm:w-[460px] w-full translate-x-[-50%] py-8 px-7 top-[50%] translate-y-[-50%]`}
          style={{
            left: !isModalOpen ? "-120%" : "50%",
          }}
        >
          <div
            className="w-[30px] h-[30px] rounded-full bg-[#F2F2F7] flex items-center justify-center cursor-pointer absolute top-4 right-4"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <NotifIcon />
          <p className="mb-0 text-[#344054] sm:text-2xl text-xl font-semibold mt-4 text-center">
            {layoutLang[String(locale)]["notif_title"]}
          </p>
          <span className="text-center text-[#667085] text-base font-medium">
            {layoutLang[String(locale)]["notif_text"]}
          </span>
          <div className="flex items-center justify-end gap-2 w-full">
            <Button
              type="primary"
              className="rounded-lg w-full h-[51px] text-base font-medium mt-4"
              onClick={handleAccept}
            >
              {layoutLang[String(locale)]["notif_button"]}
            </Button>
          </div>
        </div>
      </div>
    </NonSSRWrapper>
  )
}

export default Notification
