import { useMutation } from "@tanstack/react-query"
import { $newapi } from "services/RequestService"
import { endpoints } from "../utils/constants/apis"
import { SendAppModel } from "../utils/models/SendAppModel"
import { message } from "antd"

export function useSendApplication() {
  return useMutation({
    mutationFn: async (data: SendAppModel) => {
      const res = await $newapi.post(endpoints.SEND_APP, data)
      return res.data
    },
    onError: (res) => {
      message.error("Error")
    },
  })
}
