import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import minMax from "dayjs/plugin/minMax"
import { useRouter } from "next/router"

import { errorHandler } from "../../../utils/helpers/errorHandler"
import { Pagination } from "../../../utils/models/pagination"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { ClassSelectType } from "../utils/models/models"
import { fetchClasses, fetchComplexes } from "./queryFunctions"

dayjs.extend(minMax)

// get residential complexes
export function useGetResidentialComplexes(complex_id?: number) {
  const { query } = useRouter()

  const { url, fetch } = fetchComplexes(query, complex_id)

  return useQuery<Pagination<NewResidentalComplexModel>, ErrorRes>({
    queryKey: [queryKeys.RESIDENTIAL_COMPLEXES, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get classes for select
export function useGetClasses() {
  return useQuery<ClassSelectType[], ErrorRes>({
    queryKey: [queryKeys.CLASSES_SELECT],
    queryFn: fetchClasses,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
