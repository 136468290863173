import { useMutation } from "@tanstack/react-query"
import $api, { $newapi } from "../../../services/RequestService"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { endpoints } from "../utils/constants/endpoints"
import axios from "axios"
import { urls } from "../../../utils/constants/urls"

// call back
export function useCallBack(afterClose: () => void) {
  return useMutation(
    async (data: any) => {
      const res = await $newapi.post(endpoints.CALL_BACK, data)
      return res.data
    },
    {
      onSuccess: () => {
        // showMessage(res?.messages, messageTypes.SUCCESS)
        afterClose()
      },
      onError: errorHandler,
    }
  )
}

export function useBannerSend() {
  return useMutation(
    async (uuid?: string) => {
      const res = await axios.get(
        urls.BASE_ADS_UYSOT + endpoints.API_BANNER_VIEW({ uuid })
      )
      return res.data
    },
    {
      onSuccess(data) {
        const dataCopy = data?.data
        window.open(dataCopy?.url, dataCopy?.blank ? "_blank" : "_self")
      },
    }
  )
}
