export const clearObj = (obj: any) => {
  const objectCopy = { ...obj }

  Object.keys(objectCopy).forEach((key) => {
    if (!objectCopy[key]) {
      delete objectCopy[key]
    }
  })

  return objectCopy
}
